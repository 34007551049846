.custom-score-graph {
  height: auto;
}

@media screen and (max-width: 991px) {
  .custom-score-graph {
    width: 60vw;
  }
}

@media screen and (max-width: 767px) {
  .custom-score-graph {
    width: 65vw;
  }
}

@media screen and (max-width: 575px) {
  .custom-score-graph {
    width: 100vw;
  }
}

@media screen and (min-width: 992px) {
  .custom-score-graph {
    width: 35vw;
  }
}

.cls-1 {
  fill: #604687;
}
.cls-2 {
  fill: #fff;
}
