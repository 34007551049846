.custom-ellipses {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.notransition * {
  animation: none !important;
}

@keyframes moveFirstElipse {
  0% {
    transform: translateY(0%);
  }
  20% {
    transform: translateY(20%);
  }
  40% {
    transform: translateY(-5%);
  }
  80% {
    transform: translateY(60%);
  }
}

@keyframes moveSecondElipse {
  0% {
    transform: translateY(0%);
  }
  40% {
    transform: translateY(180%);
  }
  80% {
    transform: translateY(80%);
  }
}

@keyframes moveLeftSmallElipse {
  0% {
    transform: translateY(0%);
  }
  20% {
    transform: translateY(-140%);
  }
  37% {
    transform: translateY(75%);
  }
  65% {
    transform: translateY(-90%);
  }
}

@keyframes moveLeftBigElipse {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-20%);
  }
  80% {
    transform: translateY(5%);
  }
}

#top-elipse-container,
#second-top-elipse-container,
#grey-rectangle-top-ellipse-container,
#purple-background-bottom-ellipse-container {
  display: flex;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
}

#purple-background-top-ellipse-container {
  display: flex;
  justify-content: flex-start;
}

#top-elipse-container {
  animation: moveFirstElipse 40000ms 100ms ease-in-out forwards;
  animation-iteration-count: infinite;
}

#second-top-elipse-container {
  animation: moveSecondElipse 40000ms 100ms ease-in-out forwards;
  animation-iteration-count: infinite;
}

#left-bottom-elipse-container {
  animation: moveLeftSmallElipse 40000ms 100ms ease-in-out forwards;
  animation-iteration-count: infinite;
}

#second-left-bottom-elipse-container {
  animation: moveLeftBigElipse 40000ms 100ms ease-in-out forwards;
  animation-iteration-count: infinite;
}

@media screen and (max-width: 575px) {
  #top-elipse-container {
    margin-top: -40%;
    margin-right: -10%;
  }

  #second-top-elipse-container {
    margin-top: -25%;
  }

  #left-bottom-elipse-container {
    margin-top: 3%;
  }

  #second-left-bottom-elipse-container {
    margin-top: -85%;
  }

  #first-ellipse-top {
    width: 50%;
    height: auto;
  }

  #second-ellipse-top {
    width: 20%;
    height: auto;
  }

  #first-ellipse-bottom {
    width: 25%;
    height: auto;
  }

  #grey-rectangle-first-ellipse-bottom {
    width: 50%;
    height: auto;
  }

  #second-ellipse-bottom {
    width: 50%;
    height: auto;
  }

  #grey-rectangle-second-ellipse-bottom {
    width: 90%;
    height: auto;
  }

  #grey-rectangle-left-small-ellipse-container {
    margin-top: -15%;
    margin-left: -12%;
  }

  #grey-rectangle-left-big-ellipse-container {
    margin-top: -30%;
    margin-left: -30%;
  }

  #purple-background-top-ellipse {
    width: 40%;
    height: auto;
  }

  #purple-background-bottom-ellipse {
    width: 50%;
    height: auto;
  }

  #purple-background-bottom-ellipse-container {
    margin-right: -35%;
    margin-top: -5%;
  }
}

@media screen and (min-width: 576px) {
  #top-elipse-container {
    margin-top: -40%;
    margin-right: -10%;
  }

  #second-top-elipse-container {
    margin-top: -25%;
  }

  #left-bottom-elipse-container {
    margin-top: 3%;
  }

  #second-left-bottom-elipse-container {
    margin-top: -85%;
  }

  #first-ellipse-top {
    width: 50%;
    height: auto;
  }

  #second-ellipse-top {
    width: 20%;
    height: auto;
  }

  #first-ellipse-bottom {
    width: 25%;
    height: auto;
  }

  #grey-rectangle-first-ellipse-bottom {
    width: 30%;
    height: auto;
  }

  #second-ellipse-bottom {
    width: 50%;
    height: auto;
  }

  #grey-rectangle-second-ellipse-bottom {
    width: 70%;
    height: auto;
  }

  #grey-rectangle-left-small-ellipse-container {
    margin-top: -10%;
    margin-left: 0%;
  }

  #grey-rectangle-left-big-ellipse-container {
    margin-top: -16%;
    margin-left: 0%;
  }

  #purple-background-top-ellipse {
    width: 30%;
    height: auto;
  }

  #purple-background-bottom-ellipse {
    width: 38%;
    height: auto;
  }

  #purple-background-bottom-ellipse-container {
    margin-right: -10%;
  }
}

@media screen and (min-width: 992px) {
  #top-elipse-container {
    margin-top: -52%;
    margin-right: 0;
  }

  #second-top-elipse-container {
    margin-top: -25%;
  }

  #left-bottom-elipse-container {
    margin-top: 3%;
  }

  #second-left-bottom-elipse-container {
    margin-top: -85%;
  }

  #first-ellipse-top {
    width: 50%;
    height: auto;
  }

  #second-ellipse-top {
    width: 20%;
    height: auto;
  }

  #first-ellipse-bottom {
    width: 15%;
    height: auto;
  }

  #grey-rectangle-first-ellipse-bottom {
    width: 25%;
    height: auto;
  }

  #second-ellipse-bottom,
  #grey-rectangle-second-ellipse-bottom {
    width: 50%;
    height: auto;
  }

  #grey-rectangle-top-ellipse-container {
    margin-top: -5%;
  }

  #grey-rectangle-left-small-ellipse-container {
    margin-top: -10%;
  }

  #grey-rectangle-left-big-ellipse-container {
    margin-top: -14%;
  }

  #purple-background-top-ellipse,
  #purple-background-bottom-ellipse {
    width: 26%;
    height: auto;
  }

  #purple-background-bottom-ellipse-container {
    margin-right: 5%;
  }
}
