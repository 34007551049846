.custom-modal__container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  z-index: 6;
  cursor: initial;
  background-color: rgba(0, 0, 0, 0.25);
}

.custom-modal__container.on {
  display: block;
}

.custom-modal__container.off {
  display: none;
}

.regular-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 50vw;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  border-radius: 12.5px;
  z-index: 6;

  cursor: initial;
  padding: 1.4rem 1.9rem;
}

.regular-modal.on {
  display: block;
}

.regular-modal.off {
  display: none;
}

.regular-modal__close-button {
  position: absolute;
  top: 1.4rem;
  right: 1.9rem;

  width: 1.2rem;
  cursor: pointer;
}
