.comparation-carousel .carousel-indicators {
  position: relative;
  padding-top: 2rem;
  z-index: 1 !important;
}

.comparation-carousel .carousel-indicators button {
  background-color: var(--purple-1);
  border-radius: 100%;
  width: 0.8rem;
  height: 0.8rem;
}

.comparation-carousel .carousel-inner .carousel-item {
  transition: transform 0.3s ease-in-out;
}

@media (min-width: 992px) {
  .row-item {
    padding-right: 8rem;
    padding-left: 8rem;
  }
}
