#welcome-answer {
  border-radius: 5px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #ececec;
  background-color: rgb(255, 255, 255);
  font-family: "PoppinsRegular";
  display: flex;
  align-items: center;
  color: var(--main-text-2);
  cursor: pointer;
  margin-bottom: 2%;
}

#welcome-answer-content {
  font-family: "PoppinsRegular";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: left;
  margin-left: 2vw;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 575px) {
  #welcome-answer {
    width: 85vw;
  }

  #welcome-answer-content {
    height: 20vw;
  }
}

@media screen and (min-width: 576px) {
  #welcome-answer {
    width: 80vw;
  }

  #welcome-answer-content {
    height: 9vw;
    font-size: 2.5vw;
  }
}

@media screen and (min-width: 768px) {
  #welcome-answer-content {
    font-size: 1.7vw;
  }
}

@media screen and (min-width: 992px) {
  #welcome-answer {
    width: 60vw;
  }

  #welcome-answer-content {
    font-size: 1.2vw;
    height: 4vw;
  }
}
