.tip-card__topic {
  padding: 1.5rem 1.5rem 0 1.5rem;
  margin-bottom: 1.5rem;
}

.tip-dimension-title {
  margin: 3rem 0;
}

.tip-card__copy {
  margin-bottom: 1.5rem;
}

.tip-card__text {
  padding: 0 1.5rem;
  margin-bottom: 1.5rem;
}

.tips-info__excellence-tip,
.tips-info__focus-tip {
  margin-top: 3rem;
}

.tips-info__title {
  width: 75%;
}

@media screen and (min-width: 1200px) {
  .tips-info__title {
    font-size: 1.9vw;
    line-height: 3vw;
    margin-bottom: 1.8vw !important;
  }

  .tip-dimension-title {
    font-size: 1.6vw;
    line-height: 2.8vw;
    margin-bottom: 3vw;
  }

  .tip-card__text {
    padding: 0 1.5vw;
    margin-bottom: 1.5vw;
  }

  .tip-card__topic {
    font-size: 1.3vw;
    line-height: 1.6vw;

    padding: 1.5vw 1.5vw 0 1.5vw;
    margin-bottom: 1.5vw;
  }

  .tip-card__copy {
    font-size: 1vw;
    line-height: 1.7vw;
    margin-bottom: 1.5vw;
  }

  .tip-card__title,
  .tip-card__subtitle {
    font-size: 1.1vw;
    line-height: 1.4vw;
  }

  .tips-info__excellence-tip,
  .tips-info__focus-tip {
    margin-top: 3vw;
  }
}
