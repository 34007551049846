@font-face {
  font-family: "PoppinsSemiBold";
  src: url("../../../../assets/fonts/Poppins-SemiBold.ttf");
}

#welcome-survey-container {
  width: 100vw;
  background-color: #fff;
  position: relative;
  z-index: 0;
}

#welcome-survey-container #greeting-container {
  font-family: "PoppinsSemiBold";
  color: var(--purple-1);
  margin-bottom: 3%;
}

#welcome-survey-container #container-of-greetings-container {
  padding: 0 !important;
}

#welcome-survey-container #question-container {
  font-family: "PoppinsSemiBold";
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--main-text-1);
}

#welcome-survey-container #progress-bar-container {
  display: flex;
  margin-bottom: 10%;
  justify-content: center;
  align-items: center;
}

#welcome-survey-container #progress-bar {
  border-radius: 10px;
  background-color: #efedf1;
  margin-right: 2vw;
  margin-left: 2vw;
}

#welcome-survey-container #progress-indicator {
  border-radius: 15px;
  background-color: var(--purple-1);
}

#welcome-survey-container #go-back-icon {
  object-fit: contain;
  cursor: pointer;
}

#welcome-survey-container #go-forward-icon {
  object-fit: contain;
  cursor: pointer;
}

#welcome-survey-container #glossary {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 575px) {
  #welcome-survey-container #progress-bar {
    width: 65%;
    margin-right: 2vw;
    margin-left: 2vw;
    height: 3vw;
  }

  #welcome-survey-container #go-back-icon,
  #welcome-survey-container #go-forward-icon {
    display: block;
    width: 8vw;
  }

  #welcome-survey-container #progress-indicator {
    height: 3vw;
    width: 15vw;
  }

  #welcome-survey-container #glossary {
    bottom: -6vw;
    right: 5vw;
    position: relative;
    z-index: 5;
  }

  #welcome-survey-container #welcome-inside-container {
    padding-top: 15%;
  }
}

@media screen and (min-width: 576px) {
  #welcome-survey-container #progress-bar {
    width: 65%;
    margin-right: 2vw;
    margin-left: 2vw;
    height: 2vw;
  }

  #welcome-survey-container #go-back-icon,
  #welcome-survey-container #go-forward-icon {
    display: block;
    width: 6vw;
  }

  #welcome-survey-container #progress-indicator {
    height: 2vw;
  }

  #welcome-survey-container #glossary {
    width: 92%;
  }

  #welcome-survey-container #welcome-inside-container {
    padding-top: 10%;
  }
}

@media screen and (min-width: 992px) {
  #welcome-survey-container #question-container {
    margin-bottom: 2%;
    font-size: 45px;
  }

  #welcome-survey-container #glossary {
    padding-bottom: 10%;
    margin-top: -10%;
    width: 95%;
    position: unset;
  }

  #welcome-survey-container #progress-bar {
    width: 50%;
    margin-right: 2vw;
    margin-left: 2vw;
    height: 1vw;
  }

  #welcome-survey-container #go-back-icon,
  #welcome-survey-container #go-forward-icon {
    display: block;
    width: 4vw;
  }

  #welcome-survey-container #progress-indicator {
    height: 1vw;
  }

  #welcome-survey-container #welcome-inside-container {
    padding-top: 5%;
  }
}
