.custom-box {
  position: absolute;
  top: 100%;
  transform: translateY(-50%);

  height: 4rem;
  width: 100vw;
}

.custom-box .modal-guide {
  background-color: white;
  border-radius: 37.5px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;

  height: var(--page-navbar-height);
}

.custom-box .modal-guide .purple-section,
.custom-box .modal-guide .white-section {
  height: 90%;
  width: 50%;
  border-radius: 37.5px;

  font-family: PoppinsSemiBold;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: white;
  color: var(--main-text-2);
  cursor: pointer;
}

@media screen and (max-width: 575px) {
  .custom-box .modal-guide {
    width: 85vw;
    --page-navbar-height: 7.5vw;
    font-size: 2.7vw;
    padding: 0 0.5vw;
  }
}

@media screen and (min-width: 576px) {
  .custom-box .modal-guide {
    width: 75vw;
    --page-navbar-height: 5.7vw;
    font-size: 2vw;
    padding: 0 0.2%;
  }
}

@media screen and (min-width: 768px) {
  .custom-box .modal-guide {
    width: 75vw;
    --page-navbar-height: 4.5vw;
    font-size: 1.6vw;
  }
}

@media screen and (min-width: 992px) {
  .custom-box .modal-guide {
    width: 60vw;
    --page-navbar-height: 3.2vw;
    font-size: 1vw;
  }

  .custom-box .modal-guide,
  .custom-box .modal-guide .purple-section,
  .custom-box .modal-guide .white-section {
    border-radius: 2vw;
  }
}

body {
  --page-navbar-height: 4rem;
}
