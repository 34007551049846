#options-input #welcome-answer-options {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#options-input #answer-options {
  display: flex;
  justify-content: space-between;
}

#options-input .labels-container {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  text-align: right;

  margin-top: 1%;

  font-family: "PoppinsMedium";
  color: #9d9d9d;
}

@media screen and (max-width: 575px) {
  #options-input #welcome-answer-options {
    margin-top: 12%;
    margin-bottom: 10%;
  }

  #options-input #answer-options {
    display: flex;
    justify-content: space-between;
    margin-top: 20%;
    width: 85vw;
  }

  #options-input .labels-container {
    font-size: 3.2vw;
  }

  #options-input .option-label {
    width: 30%;
    text-align: center;
    margin-top: 1%;
  }
}

@media screen and (min-width: 576px) {
  #options-input #welcome-answer-options {
    margin-top: 12%;
    margin-bottom: 10%;
  }

  #options-input #answer-options {
    display: flex;
    justify-content: space-between;
    width: 80vw;
  }

  #options-input .labels-container {
    font-size: 1.5vw;
    width: auto;
  }

  #options-input .option-label {
    width: auto;
    margin-top: 0;
  }
}

@media screen and (min-width: 992px) {
  #options-input #welcome-answer-options {
    margin-top: 7%;
    margin-bottom: 5%;
  }

  #options-input #answer-options {
    display: flex;
    justify-content: space-between;
    margin-top: 12%;
    width: 60vw;
  }

  #options-input .labels-container {
    margin-bottom: 5%;
    font-size: 12px;
  }
}
