#new-error-page {
  background-color: #f2eff6;
  width: 100%;
}

#new-error-page .logo {
  width: 13rem;
  cursor: pointer;
}

#new-error-page .yogaGirl {
  width: 35%;
}

#new-error-page .card-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

#new-error-page .card {
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  width: 27rem;
}

#new-error-page .card-title {
  font-family: PoppinsSemiBold;
  margin-top: 5%;
}

#new-error-page .card-subtitle {
  margin-top: 5%;
  margin-bottom: 5%;
}

@media screen and (max-width: 575px) {
  #new-error-page .yogaGirl {
    width: 100%;
    padding: 0 4rem;
    margin-top: 10%;
  }

  #new-error-page .card {
    margin: 0 1rem;
  }
}

@media screen and (min-width: 576px) {
  #new-error-page .yogaGirl {
    width: 50%;
    margin-top: 0;
  }

  #new-error-page .card-container {
    margin-bottom: 10%;
  }

  #new-error-page .card {
    width: 35rem;
  }
}

@media screen and (min-width: 992px) {
  #new-error-page .yogaGirl {
    width: 30%;
    margin-top: 0%;
  }

  #new-error-page .card-container {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 2000px) {
  #new-error-page .card-container {
    z-index: 1;
  }

  #new-error-page #main-container-error-page {
    margin-top: 5%;
  }

  #new-error-page .yogaGirl {
    width: 40%;
    margin-top: 5%;
    margin-left: -23%;
  }
}
