#progress-overtime-container {
  min-width: 0;
}

#new-my-analytics {
  padding-left: 0 !important;
}

#new-my-analytics .navbar {
  margin-bottom: 0 !important;
}

#progress-overtime-container #progress-over-time-title {
  font-family: PoppinsSemiBold;
}

#progress-overtime-container .filter-errors {
  font-family: "PoppinsBold";
  display: flex;
  justify-content: center;
  color: #cdbae7;
}

#progress-overtime-container #period-buttons-container {
  border-radius: 37.5px;
  background-color: #f2eff6;
  font-family: "PoppinsSemiBold";
  cursor: pointer;
}

#progress-overtime-container .period-buttons {
  border-radius: 33.5px;
  background-color: #604586;
  display: flex;
  justify-content: center;
  align-items: center;
}

#progress-overtime-container #filters-container {
  font-family: "PoppinsRegular";
}

#progress-overtime-container #analytics-graph-container {
  position: relative;
}

#progress-overtime-container .save-button {
  color: rgba(60, 149, 83, 1);
  cursor: pointer;
  text-align: right;
}

#progress-overtime-container .custom-native-date-picker {
  padding-left: 1.5vw;
}

#progress-overtime-container .custom-native-date-picker::placeholder {
  font-style: italic;
}

@media screen and (max-width: 575px) {
  #progress-overtime-container #progress-over-time-title {
    margin-top: 10%;
    margin-bottom: 5%;
    width: 75%;
    text-align: center;
  }

  #progress-overtime-container #period-buttons-container {
    width: 90vw;
    height: 7vw;
    font-size: 2vw;
  }

  #progress-overtime-container .period-buttons {
    width: 20vw;
    height: 6vw;
  }

  #progress-overtime-container #filters-container {
    margin-top: 2%;
    width: 99%;
    margin-bottom: 5%;
    overflow-x: scroll;
  }

  #progress-overtime-container #filters-container-inside {
    width: 300%;
    margin-bottom: 40%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 1%;
  }

  #progress-overtime-container #analytics-graph-container {
    margin-bottom: 10%;
    width: 90%;
    margin-top: -30vw;
  }

  #progress-overtime-container #loading-animation-graph {
    margin-top: -83vw;
    margin-bottom: 60vw;
  }

  #progress-overtime-container .custom-native-date-picker::placeholder {
    font-size: 2vw;
  }

  #progress-overtime-container .save-button {
    font-size: 2vw;
  }

  #progress-overtime-container .filter-errors {
    font-size: 1.6vw;
  }

  #progress-overtime-container #period-buttons-description {
    font-size: 2.5vw;
    text-align: center;
    width: 80vw;
    margin-top: 4%;
    margin-bottom: 1%;
  }

  #filters-container::-webkit-scrollbar {
    height: 0 !important;
  }

  #filters-container::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}

@media screen and (min-width: 576px) {
  #progress-overtime-container #progress-over-time-title {
    margin-top: 10%;
    margin-bottom: 5%;
    width: 50%;
    text-align: center;
  }

  #progress-overtime-container #period-buttons-container {
    width: 80vw;
    height: 7vw;
    font-size: 2vw;
  }

  #progress-overtime-container .period-buttons {
    width: 20vw;
    height: 6vw;
  }

  #progress-overtime-container #filters-container {
    margin-top: 2%;
    width: 80%;
    margin-bottom: 5%;
    overflow-x: scroll;
  }

  #progress-overtime-container #filters-container-inside {
    width: 300%;
    margin-bottom: 30%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 1%;
  }

  #progress-overtime-container #analytics-graph-container {
    margin-bottom: 20%;
    width: 80%;
    margin-top: -20vw;
  }

  #progress-overtime-container #loading-animation-graph {
    margin-top: -75vw;
    margin-bottom: 50vw;
  }

  #progress-overtime-container .custom-native-date-picker::placeholder {
    font-size: 1.3vw;
  }

  #progress-overtime-container .save-button {
    font-size: 1.3vw;
  }

  #progress-overtime-container .filter-errors {
    font-size: 1.3vw;
  }

  #progress-overtime-container #period-buttons-description {
    font-size: 1.3vw;
    margin-top: 3%;
    width: auto;
    margin-bottom: 0;
  }

  #filters-container::-webkit-scrollbar {
    height: 0 !important;
  }

  #filters-container::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}

@media screen and (min-width: 992px) {
  #progress-overtime-container #progress-over-time-title {
    margin-top: 10%;
    font-size: 2vw;
    margin-bottom: 5%;
    width: 100%;
    text-align: unset;
  }

  #progress-overtime-container #period-buttons-container {
    width: 80vw;
    height: 4vw;
    font-size: 1vw;
  }

  #progress-overtime-container .period-buttons {
    width: 20vw;
    height: 3.5vw;
  }

  #progress-overtime-container #filters-container {
    margin-top: 1%;
    width: 100%;
    margin-bottom: 5%;
    overflow-x: unset;
  }

  #progress-overtime-container #filters-container-inside {
    width: 80%;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  #progress-overtime-container #analytics-graph-container {
    margin-bottom: 10%;
    width: 90%;
    margin-top: 0;
  }

  #progress-overtime-container #loading-animation-graph {
    margin-top: -40vw;
    margin-bottom: 30vw;
  }

  #progress-overtime-container .custom-native-date-picker::placeholder {
    font-size: 0.8vw;
  }

  #progress-overtime-container .save-button {
    font-size: 0.8vw;
  }

  #progress-overtime-container .filter-errors {
    font-size: 0.8vw;
    margin-top: 1%;
  }

  #progress-overtime-container #period-buttons-description {
    font-size: 1vw;
    margin-top: 3%;
    width: auto;
    margin-bottom: 0;
  }

  #filters-container::-webkit-scrollbar {
    height: auto !important;
  }

  #filters-container::-webkit-scrollbar {
    display: block; /* for Chrome, Safari, and Opera */
  }
}
