.focus-and-evolution__focus-row,
.focus-and-evolution__progress-row {
  line-height: 1.8rem;
  font-size: 105%;
  color: var(--main-text-1);
}

.focus-and-evolution__focus-title,
.focus-and-evolution__progress-title {
  margin-bottom: 1.5rem;
}

.focus-and-evolution__focus-body,
.focus-and-evolution__progress-body {
  margin-bottom: 2rem;
}

.focus-and-evolution__focus-row {
  margin-bottom: 6rem;
}

@media (min-width: 768px) and (max-width: 991px) {
  #focus-and-evolution__progress-image,
  #focus-and-evolution__focus-image {
    padding: 0 15rem !important;
  }
}

@media (min-width: 992px) {
  .focus-and-evolution__progress-row {
    margin-left: 3rem;
  }

  #focus-and-evolution__focus-image {
    margin-left: 5rem;
  }
}

@media screen and (min-width: 1200px) {
  .focus-and-evolution__progress-row {
    margin-left: 3.5vw;
    margin-bottom: 0;
  }

  .focus-and-evolution__progress-title,
  .focus-and-evolution__focus-title {
    font-size: 1.7vw !important;
    line-height: 2.1vw;
    margin-bottom: 1vw !important;
  }

  .focus-and-evolution__progress-body,
  .focus-and-evolution__focus-body {
    /* You seem to be doing ok... */
    font-size: 1.07vw;
    line-height: 1.8vw;
    margin-bottom: 2vw !important;
  }
}
