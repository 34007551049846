.password-input {
  position: relative;
}

.password-input__eyelid {
  position: absolute;
  cursor: pointer;
  top: 50%;
}

.confirm-password-page__input .password-input__eyelid {
  width: 5%;
  right: 4%;
}

.about-me__inputs .password-input__eyelid {
  top: 45%;
  width: 3%;
  right: 2%;
}
