.social-media-icon__wrapper {
  padding: 13px;
  width: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;

  cursor: pointer;

  border-radius: 100%;

  -webkit-filter: drop-shadow(0 4px 25px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0 4px 25px rgba(0, 0, 0, 0.1));
}

@media screen and (min-width: 1200px) {
  .social-media-icon__wrapper {
    padding: 0.9vw;
    width: 3.5vw;
  }
}
