.zone-and-score {
  background-color: #f9f8fc;
  font-size: 110%;
}

.zone-and-score__intro {
  font-family: PoppinsSemiBold;
  margin-bottom: 1.5rem;
}

.zone-and-score__zone {
  margin-bottom: 1.5rem;
}

.zone-and-score__last-result-subtitle {
  margin-bottom: 3rem;
  font-size: calc((1.325rem + 0.9vw) / 1.9);
}

.zone-and-score__description-container {
  margin-bottom: 3rem;
}

.last-results__zone-and-score {
  margin-top: calc(var(--page-navbar-height) / 2) !important;
}

.preliminary-image-like svg {
  width: 4rem;
  height: auto;
}

@media screen and (max-width: 991px) {
  .zone-and-score__text {
    text-align: center;
  }

  .zone-and-score__description {
    width: 55%;
  }
}

@media screen and (max-width: 767px) {
  .zone-and-score__description {
    width: 75%;
  }
}

@media screen and (min-width: 992px) {
  .zone-and-score {
    padding: 3rem 0;
  }

  .zone-and-score__description-container {
    width: 70%;
  }

  .zone-and-score__text {
    margin-left: 7rem;
  }
}

@media screen and (min-width: 1200px) {
  .zone-and-score {
    padding: 4vw 0;
  }

  .zone-and-score__text {
    margin-left: 8vw !important;
  }

  .zone-and-score__intro {
    /* sub-title "Today you are in the" */
    font-size: 2.1vw !important;
    line-height: 3vw;
    margin-bottom: 1.8vw !important;
  }

  .zone-and-score__last-result-subtitle {
    /* sub-sub-title "Results from" */
    font-size: 1vw !important; /* this can change */
    margin-bottom: 2vw;
  }

  .zone-and-score__zone {
    /* mega-title "Yellow Zone" */
    font-size: 5vw !important;
    line-height: 7vw !important;
    margin-bottom: 1.5vw !important;
  }

  .zone-and-score__preliminary-subtitle {
    font-size: 1.8vw !important;
    line-height: 2.3vw;
  }

  .zone-and-score__description-container {
    margin-bottom: 3.2vw;
  }

  .zone-and-score__description {
    /* You seem to be doing ok... */
    font-size: 1.1vw;
    line-height: 2vw;
  }
}

@media screen and (max-width: 575px) {
  .last-results__zone-and-score {
    font-size: calc(1rem + 1.5vw);
  }
}
