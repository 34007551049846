#tip-card-main-div {
  overflow-y: scroll;
}

@media screen and (max-width: 575px) {
  #tip-card-main-div {
    height: 120vw;
    overflow-y: scroll;
  }
}

@media screen and (min-width: 576px) {
  #tip-card-main-div {
    height: 92vw;
    overflow-y: scroll;
  }
}

@media screen and (min-width: 992px) {
  #tip-card-main-div {
    height: 42.5vw;
    overflow-y: scroll;
  }
}
