.mood_button {
  flex-grow: 0;
  border-radius: 5px;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #ececec;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "PoppinsMedium";
  font-size: 25px;
  color: #9d9d9d;
  cursor: pointer;
}

.mood_button_label {
  text-align: center;
}

@media screen and (max-width: 575px) {
  .mood_button {
    width: 20vw;
    height: 8vh;
  }
}

@media screen and (min-width: 576px) {
  .mood_button {
    width: 17vw;
    height: 8vh;
  }
}

@media screen and (min-width: 992px) {
  .mood_button {
    width: 13vw;
    height: 8vh;
  }
}
