.comparation-carousel__number {
  font-size: 1200%;
  font-weight: 300;
  line-height: 1.2;
}

.comparation-carousel__carousel {
  height: 14rem;
}

.comparation-carousel__text {
  margin-top: 1.5rem;
  width: 13rem;
}

@media screen and (min-width: 1200px) {
  .comparation-carousel__number {
    font-size: 14vw;
  }

  .comparation-carousel__carousel {
    height: 14vw;
  }

  .comparation-carousel__text {
    margin-top: 1.5vw;
    font-size: 1vw;
    line-height: 1.5vw;
    width: 16vw;
  }
}
