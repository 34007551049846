.custom-flash {
  position: fixed;
  right: 2vw;
  z-index: 6;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  padding: 1rem;
  transition: bottom 2s ease 0s;
}

.custom-flash {
  animation: 1.5s ease-out 0s 1 slideFlashToIn forwards;
}

@keyframes slideFlashToIn {
  0% {
    bottom: -2vw;
  }
  100% {
    bottom: 2vw;
  }
}
