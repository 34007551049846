@font-face {
  font-family: "PoppinsBold";
  src: url("../../assets/fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: "PoppinsRegular";
  src: url("../../assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "PoppinsMedium";
  src: url("../../assets/fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "PoppinsSemiBold";
  src: url("../../assets/fonts/Poppins-SemiBold.ttf");
}

body {
  font-family: PoppinsRegular !important;
  font-style: normal;
  font-weight: normal;
  color: var(--main-text-1);
}

* {
  box-sizing: border-box;
}

img {
  width: 100%;
  height: auto;
}

.ff-popp-bold {
  font-family: PoppinsBold;
}

.ff-popp-med {
  font-family: PoppinsMedium;
}

.ff-popp-semi-bold {
  font-family: PoppinsSemiBold;
}

.ff-popp-reg {
  font-family: PoppinsRegular;
}

@media screen and (max-width: 992px) {
  .custom-violet-title {
    /* h2 / h3 */
  }

  .custom-violet-subtitle {
    /* h3 / h4 */
  }

  .custom-violet-text {
    /* div / p */
    line-height: 1.8rem; /* confirmed */
  }
}

@media screen and (min-width: 992px) {
  .custom-violet-title {
    font-size: 2.2vw !important; /* confirmed */
    line-height: 1.2; /* confirmed */
  }

  .custom-violet-subtitle {
    font-size: 1.6vw; /* confirmed */
  }

  .custom-violet-text {
    font-size: 1.3vw; /* confirmed */
    line-height: 2.5vw; /* confirmed */
  }
}

:root {
  --purple-1: #604586;
  --yellow-1: #fec804;
  --purple-2: #decff3;
  --hover-outline: #f9f5fe;
  --hover-fill: #573c7c;
  --pressed-fill: #4c366b;
  --main-text-1: #333;
  --pressed-outline: #e5dbf3;
  --delete-account-button-pressed: #f3eef9;
  --main-text-2: #666;
  --pressed-outline: #e5dbf3;
  --pressed-outline-delete-account: #f3eef9;
  --hover-outline-delete-account: #f9f7fd;
  --glossary-button-active: #decff3;
  --glossary-button-hover: #d6c5ee;
  --glossary-button-pressed: #ccb9e6;
  --survey-field-pressed: #cdbae7;
  --green-circle: #7dba61;
  --arrow-up-numbers: #b49dd6;
  --disabled-button: #c2c0c7;
  --extra-large-screens: 1200px;
  --large-screens: 992px;
  --medium-screens: 768px;
  --small-screens: 576px;
  --extra-small-screens: 0px;
}
