#meet-violet-container {
  background-color: #fff;
}

#meet-violet-container #page-title {
  font-family: "PoppinsBold";
  color: var(--main-text-1);
}

#meet-violet-container #first-rectangle {
  width: 100%;
  background-color: #f2eff6;
}

#meet-violet-container .navbar {
  margin-bottom: 0 !important;
}

#meet-violet-container #second-rectangle-left-section,
#meet-violet-container #second-rectangle-right-section,
#meet-violet-container #third-rectangle-left-section,
#meet-violet-container #third-rectangle-right-section {
  margin-top: 5%;
  margin-bottom: 5%;
}

#meet-violet-container #second-rectangle-left-section,
#meet-violet-container #third-rectangle-right-section {
  width: 70%;
}

#meet-violet-container #second-rectangle-right-section {
  width: 50%;
}

#meet-violet-container #third-rectangle-left-section {
  width: 70%;
}

#meet-violet-container #fourth-rectangle-bottom-section {
  margin-bottom: 5%;
}

#meet-violet-container #second-rectangle-left-section-title,
#meet-violet-container #third-rectangle-right-section-title,
#meet-violet-container #fourth-rectangle-title,
#meet-violet-container #fifth-rectangle-title,
#meet-violet-container #invite-sentence {
  font-family: "PoppinsSemiBold";
}

#meet-violet-container .fourth-section-small-titles {
  font-family: "PoppinsSemiBold";
  margin-top: 2%;
}

#meet-violet-container #fourth-rectangle-title {
  margin-top: 5%;
  margin-bottom: 5%;
  width: 100%;
}

#meet-violet-container #second-rectangle-left-section-description,
#meet-violet-container #third-rectangle-right-section-description {
  font-family: "PoppinsRegular";
  color: var(--main-text-1);
}

#meet-violet-container .fourth-rectangle-description {
  font-family: "PoppinsRegular";
}

#meet-violet-container #third-rectangle,
#meet-violet-container #fifth-rectangle {
  background-color: #f2eff6;
}

#meet-violet-container #join-now-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32.5px;
  background-color: var(--main-text-1);
  color: white;
  font-family: "PoppinsMedium";
  cursor: pointer;
}

#meet-violet-container #login-description {
  font-family: "PoppinsRegular";
}

#meet-violet-container #login-link {
  text-decoration: underline;
  color: var(--purple-1);
  cursor: pointer;
}

#meet-violet-container #take-the-survey-button,
#meet-violet-container #review-results-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32.5px;
  background-color: var(--purple-1);
  color: white;
  cursor: pointer;
}

#meet-violet-container #disabled-survey-button-text {
  font-family: "PoppinsRegular";
  margin-top: 3%;
  color: var(--purple-1);
}

#meet-violet-container .share-icons {
  cursor: pointer;
}

#meet-violet-container #purple-reference-sentencer,
#meet-violet-container #why-purpose {
  font-family: "PoppinsSemiBold";
}

#meet-violet-container .fourth-section-small-titles {
  text-align: center;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 575px) {
  #meet-violet-container #second-rectangle-left-section-title {
    width: 100%;
    margin-top: 5%;
  }

  #meet-violet-container #second-rectangle-image,
  #meet-violet-container #third-rectangle-image {
    width: 60vw;
  }

  #meet-violet-container #third-rectangle-right-section-title {
    width: 100%;
    text-align: center;
    margin-top: 5%;
  }

  #meet-violet-container #fourth-rectangle-title {
    text-align: center;
    width: 90%;
    margin-top: 10%;
  }

  #meet-violet-container #fifth-rectangle-title {
    font-size: 5vw;
  }

  #meet-violet-container #second-rectangle-left-section-description {
    width: 100%;
    text-align: center;
  }

  #meet-violet-container #third-rectangle-right-section-description {
    width: 100%;
    text-align: center;
  }

  #meet-violet-container #third-rectangle-image {
    margin-top: 0;
  }

  #meet-violet-container .fourth-section-images {
    width: 25vw;
  }

  #meet-violet-container .fourth-rectangle-description {
    width: 55%;
    margin-top: 1%;
    text-align: center;
  }

  #meet-violet-container .fourth-section-small-titles {
    text-align: center;
  }

  #meet-violet-container #join-now-button {
    width: 35vw;
    height: 10vw;
    font-size: 3vw;
  }

  #meet-violet-container #fifth-rectangle-title {
    width: 50%;
    text-align: center;
  }

  #meet-violet-container #fifth-rectangle-join-section {
    width: 35%;
  }

  #meet-violet-container #login-description {
    font-size: 2vw;
    margin-top: 2%;
  }

  #meet-violet-container #fifth-rectangle-join-section {
    margin-top: 1%;
    margin-bottom: 5%;
  }

  #meet-violet-container #fifth-rectangle-title {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  #meet-violet-container .fourth-rectangle-buttons {
    width: 55%;
  }

  #meet-violet-container .share-icons {
    width: 20vw;
  }

  #meet-violet-container #take-the-survey-button {
    font-size: 0.9rem;
    width: 100%;
    height: 12vw;
    text-align: center;
  }

  #meet-violet-container #review-results-button {
    font-size: 0.9rem;
    width: 100%;
    height: 12vw;
    text-align: center;
  }

  #meet-violet-container .description-text {
    line-height: 1.8rem;
  }

  #meet-violet-container #disabled-survey-button-text {
    font-size: 2vw;
  }

  #meet-violet-container #first-small-section-image {
    margin-top: 10%;
  }

  #meet-violet-container #purple-reference-sentencer {
    line-height: 1.8rem;
  }

  #meet-violet-container #why-purpose {
    margin-top: 15%;
    margin-bottom: 7%;
  }
}

@media screen and (min-width: 420px) {
  #meet-violet-container #take-the-survey-button {
    font-size: 0.9rem;
    width: 80%;
    height: 10vw;
    text-align: center;
  }

  #meet-violet-container #review-results-button {
    font-size: 0.9rem;
    width: 80%;
    height: 10vw;
    text-align: center;
  }
}

@media screen and (min-width: 576px) {
  #meet-violet-container #second-rectangle-left-section-title {
    width: 100%;
    text-align: center;
    margin-top: 5%;
  }

  #meet-violet-container #second-rectangle-image,
  #meet-violet-container #third-rectangle-image {
    width: 45vw;
  }

  #meet-violet-container #third-rectangle-right-section-title {
    width: 100%;
    text-align: center;
    margin-top: 5%;
  }

  #meet-violet-container #fourth-rectangle-title {
    text-align: center;
    margin-top: 7%;
  }

  #meet-violet-container #fifth-rectangle-title {
    font-size: 3.2vw;
  }

  #meet-violet-container #second-rectangle-left-section-description {
    width: 100%;
    text-align: center;
  }

  #meet-violet-container #third-rectangle-right-section-description {
    width: 100%;
    text-align: center;
  }

  #meet-violet-container #third-rectangle-image {
    margin-top: 0;
  }

  #meet-violet-container .fourth-section-images {
    width: 20vw;
  }

  #meet-violet-container .fourth-rectangle-description {
    width: 55%;
    margin-top: 1%;
    text-align: center;
  }

  #meet-violet-container #join-now-button {
    width: 27vw;
    height: 8vw;
    font-size: 2.5vw;
  }

  #meet-violet-container #fifth-rectangle-title {
    width: 100%;
    text-align: unset;
  }

  #meet-violet-container #fifth-rectangle-join-section {
    width: 35%;
  }

  #meet-violet-container #login-description {
    font-size: 1.5vw;
    margin-top: 2%;
  }

  #meet-violet-container #fifth-rectangle-join-section {
    margin-top: 1%;
    margin-bottom: 5%;
  }

  #meet-violet-container #fifth-rectangle-title {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  #meet-violet-container .fourth-rectangle-buttons {
    width: 55%;
  }

  #meet-violet-container .share-icons {
    width: 15vw;
  }

  #meet-violet-container #invite-sentence {
    font-size: 105%;
  }

  #meet-violet-container #take-the-survey-button {
    font-size: 0.9rem;
    width: 35vw;
    height: 8vw;
  }

  #meet-violet-container #review-results-button {
    font-size: 0.9rem;
    width: 35vw;
    height: 8vw;
  }

  #meet-violet-container .description-text {
    line-height: 1.8rem;
  }

  #meet-violet-container #disabled-survey-button-text {
    font-size: 1.2vw;
  }

  #meet-violet-container #why-purpose {
    margin-top: 6%;
  }

  #meet-violet-container #purple-reference-sentencer {
    line-height: 1.8rem;
  }
}

@media screen and (min-width: 992px) {
  #meet-violet-container #second-rectangle-left-section-title {
    width: 65%;
    text-align: left;
    margin-top: 0;
  }

  #meet-violet-container #second-rectangle-image,
  #meet-violet-container #third-rectangle-image {
    width: 35vw;
  }

  #meet-violet-container #third-rectangle-right-section-title {
    width: 75%;
    text-align: left;
    margin-top: 0;
  }

  #meet-violet-container #fifth-rectangle-title {
    font-size: 2.7vw;
  }

  #meet-violet-container #second-rectangle-left-section-description {
    width: 65%;
    text-align: unset;
    line-height: 2vw;
  }

  #meet-violet-container #third-rectangle-right-section-description {
    width: 75%;
    text-align: unset;
    line-height: 2vw;
  }

  #meet-violet-container #third-rectangle-image {
    margin-top: 10%;
  }

  #meet-violet-container .fourth-section-images {
    width: 10vw;
  }

  #meet-violet-container .fourth-rectangle-description {
    width: 22%;
    margin-top: 1%;
    line-height: 2vw;
  }

  #meet-violet-container .fourth-rectangle-buttons {
    width: 22%;
  }

  #meet-violet-container .fourth-section-small-titles {
    width: 20%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 1.6vw;
  }

  #meet-violet-container #join-now-button {
    width: 20vw;
    height: 5vw;
    font-size: 1.5vw;
  }

  #meet-violet-container #fifth-rectangle-title {
    width: 65%;
  }

  #meet-violet-container #fifth-rectangle-join-section {
    width: 35%;
  }

  #meet-violet-container #login-description {
    font-size: 1.1vw;
    margin-top: 2%;
  }

  #meet-violet-container #fifth-rectangle-join-section,
  #meet-violet-container #fifth-rectangle-title {
    margin-top: 15%;
    margin-bottom: 15%;
  }

  #meet-violet-container .share-icons {
    width: 7vw;
  }

  #meet-violet-container #invite-sentence {
    font-size: 1.6vw;
    line-height: 1.2;
    margin-top: 3%;
  }

  #meet-violet-container #take-the-survey-button {
    font-size: 1.1vw;
    width: 18vw;
    height: 4vw;
    margin-top: 3%;
  }

  #meet-violet-container #review-results-button {
    font-size: 1.1vw;
    width: 20vw;
    height: 4vw;
    margin-top: 3%;
  }

  #meet-violet-container .description-text {
    line-height: 2vw;
  }

  #meet-violet-container #disabled-survey-button-text {
    font-size: 0.7vw;
  }

  #meet-violet-container #why-purpose {
    margin-top: 7%;
  }

  #meet-violet-container #purple-reference-sentencer {
    line-height: 2vw;
  }
}

@media screen and (min-width: 1200px) {
  #meet-violet-container #fourth-rectangle-title {
    font-size: 1.7vw;
    line-height: 3vw;
    margin-top: 5%;
  }

  #meet-violet-container #second-rectangle-left-section-title,
  #meet-violet-container #third-rectangle-right-section-title,
  #meet-violet-container .fourth-section-small-titles {
    font-size: 1.6vw;
    line-height: 3vw;
  }

  #meet-violet-container #second-rectangle-left-section-description,
  #meet-violet-container #third-rectangle-right-section-description,
  #meet-violet-container .fourth-rectangle-description {
    font-size: 1.1vw;
    line-height: 2vw;
  }
}

@media screen and (min-width: 2000px) {
  #meet-violet-container #take-the-survey-button,
  #meet-violet-container #review-results-button {
    border-radius: 90px;
  }
}
