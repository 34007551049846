.navBarActionMenu {
  position: relative;
}

.navBarActionMenu .hamburguer-button {
  z-index: 10;
  cursor: pointer;
  position: relative;
}

.menuNav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 10;
  overflow: hidden;
  max-height: 40vh;
  z-index: 9;
  padding: 0 !important;
  background-color: white;
}

.menuNav.closeMenu {
  animation: close 0.5s;
  animation-timing-function: linear;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.menuNav.showMenu {
  height: 100%;
  animation: open 0.5s;
  animation-timing-function: linear;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.navBarActionMenu #share-button,
.navBarActionMenu #about-me-button,
.navBarActionMenu #about-us-button,
.navBarActionMenu #my-analitics-button,
.navBarActionMenu #to-login-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: "PoppinsMedium";
  font-size: 1.5vw;
  cursor: pointer;
  color: black !important;
}

.navBarActionMenu #share-button {
  font-family: "PoppinsRegular";
}

.navBarActionMenu #list {
  overflow-y: scroll;
}

.navBarActionMenu #email-sharing-button-actions-menu,
.navBarActionMenu #whatsapp-sharing-button-actions-menu,
.navBarActionMenu #messenger-sharing-button-actions-menu {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: black;
  cursor: pointer;
}

.navBarActionMenu #signup-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32.5px;
  background-color: var(--purple-1);
  color: white;
  border: 0;
}

.navBarActionMenu #items-container {
  width: 100%;
}

@keyframes close {
  0% {
    transform: translateY(0%);
    height: 100%;
    opacity: 1;
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  }
  50% {
    opacity: 0;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
  }
  100% {
    transform: translateY(-100%);
    height: 0;
    opacity: 0;
    box-shadow: 0 4px 0px 0 rgba(0, 0, 0, 0.1);
  }
}

@keyframes open {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@media screen and (max-width: 575px) {
  .menuNav {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 0;
    overflow: hidden;
    max-height: 85vw;
    z-index: 9;
  }

  .navBarActionMenu .hamburguer-button {
    right: 7%;
    top: 7%;
  }

  .navBarActionMenu #about-me-button,
  .navBarActionMenu #about-us-button,
  .navBarActionMenu #my-analitics-button,
  .navBarActionMenu #signup-button,
  .navBarActionMenu #to-login-button {
    font-size: 4vw;
    margin-bottom: 5%;
  }

  .navBarActionMenu #signup-button {
    width: 25vw;
    height: 8vw;
  }

  .navBarActionMenu #share-button {
    font-size: 3.3vw;
  }

  .navBarActionMenu #icons-container {
    width: 40vw;
  }

  .navBarActionMenu #items-container {
    height: 80vw;
  }

  .navBarActionMenu #first-element {
    height: 25vw;
  }

  .navBarActionMenu #email-sharing-button-actions-menu,
  .navBarActionMenu #whatsapp-sharing-button-actions-menu,
  .navBarActionMenu #messenger-sharing-button-actions-menu {
    width: 60%;
  }

  .navBarActionMenu #first-element {
    margin-top: 27%;
  }

  .navBarActionMenu .aboutme-icon {
    position: absolute;
    left: 35%;
    bottom: 42%;
  }
}

@media screen and (min-width: 576px) {
  .menuNav {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 0;
    overflow: hidden;
    max-height: 70vw;
    z-index: 9;
  }

  .navBarActionMenu .hamburguer-button {
    right: 2vw;
    top: 7%;
  }

  .navBarActionMenu #about-me-button,
  .navBarActionMenu #about-us-button,
  .navBarActionMenu #my-analitics-button,
  .navBarActionMenu #signup-button,
  .navBarActionMenu #to-login-button {
    font-size: 2.3vw;
  }

  .navBarActionMenu #signup-button {
    width: 20vw;
    height: 6vw;
  }

  .navBarActionMenu #share-button {
    font-size: 1.8vw;
  }

  .navBarActionMenu #icons-container {
    width: 20vw;
  }

  .navBarActionMenu #items-container {
    height: 60vw;
  }

  .navBarActionMenu #first-element {
    height: 20vw;
  }

  .navBarActionMenu #first-element {
    margin-top: 20%;
  }

  .navBarActionMenu .aboutme-icon {
    position: absolute;
    left: 40%;
    top: 52.5%;
  }
}
