.error-labels {
  float: right;
  font-size: 0.9rem;
  color: rgb(199, 124, 235);
}

#compare-with-others-date-picker .react-datepicker-wrapper {
  width: 100%;
}

.compare-with-others__title {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem !important;
}

.compare-with-others__subtitle {
  margin-bottom: 3rem;
}

.comparation-with-others__progress-overtime-button {
  padding-top: 1.5rem;
}

.compare-with-others-info__title {
  margin-bottom: 1.5rem;
}

.compare-with-others-info__subtitle {
  margin-bottom: 3rem;
}

.compare-with-others-info__input {
  margin-bottom: 1.5rem;
}

.compare-with-others__title {
  max-width: 100% !important;
  width: 100% !important;
}

@media screen and (min-width: 992px) {
  #more-details-button-compare-with-others {
    margin-top: 3%;
  }
}

@media screen and (min-width: 1200px) {
  .compare-with-others__title {
    font-size: 1.9vw;
    line-height: 3vw;
    margin-bottom: 1vw !important;
  }

  .compare-with-others__subtitle {
    font-size: 1.1vw;
    line-height: 1vw;
    margin-bottom: 3vw;
  }

  .comparation-with-others__progress-overtime-button {
    padding-top: 1.5vw;
  }

  .compare-with-others-info__title {
    font-size: 1.7vw;
    line-height: 2vw;
    margin-bottom: 1vw;
  }

  .compare-with-others-info__subtitle {
    font-size: 1vw;
    line-height: 1.8vw;
    margin-bottom: 1.5vw;
  }

  .compare-with-others-info__input {
    margin-bottom: 1vw;
  }

  .comparation-with-others__progress-overtime-button button {
    margin-top: 2vw;
  }
}
