#grey-background-rectangle,
#grey-background-rectangle-about-me {
  background-color: #f2eff6;
  font-family: "PoppinsBold";
  position: relative;
}

#purple-background-subtitle {
  font-family: "PoppinsRegular";
}

@media screen and (max-width: 575px) {
  .greyBackgroundPageTitle {
    font-size: 5.5vw;
  }
  #grey-background-rectangle,
  #grey-background-rectangle-about-me {
    height: 35vw;
  }

  #purple-background-subtitle {
    font-size: 2.5vw;
    width: 60%;
  }

  #purple-background-title {
    font-size: 5vw;
  }
}

@media screen and (min-width: 576px) {
  .greyBackgroundPageTitle {
    font-size: 4.5vw;
  }
  #grey-background-rectangle,
  #grey-background-rectangle-about-me {
    height: 35vw;
  }

  #purple-background-subtitle {
    font-size: 2vw;
    width: 45%;
  }

  #purple-background-title {
    font-size: 5vw;
  }
}

@media screen and (min-width: 992px) {
  .greyBackgroundPageTitle {
    font-size: 2.7vw;
  }

  #grey-background-rectangle {
    height: 20vw;
  }
  #grey-background-rectangle-about-me {
    height: 25vw;
  }

  #purple-background-subtitle {
    width: 100%;
    font-size: 1.3vw;
  }

  #purple-background-title {
    font-size: 3vw;
  }
}
