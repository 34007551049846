.cookies-policy-modal {
  animation: 1.5s ease-out 0s 1 slideCookiesPolicyModal forwards;
}

@keyframes slideCookiesPolicyModal {
  0% {
    top: 120%;
  }
  100% {
    top: 100%;
  }
}

@media screen and (min-width: 1600px) {
  .cookies-policy__text {
    font-size: 1.2vw;
  }
}
