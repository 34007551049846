#share-info .custom-flex-space-wraped > button {
  margin: 0 1.5rem;
}

#share-info .custom-flex-space-all > button {
  margin: 0 0.5rem; /* test with mins */
}

.share-info__title {
  padding-bottom: 1.5rem;
}

@media screen and (min-width: 1200px) {
  .share-info__title {
    font-size: 1.9vw;
    line-height: 3vw;
    margin-bottom: 0.8vw;
  }

  #share-info .custom-flex-space-all > button {
    margin: 0 0.5vw; /* test with mins */
  }
}
