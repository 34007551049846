@font-face {
  font-family: "PoppinsBold";
  src: url("../../../assets/fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: "PoppinsRegular";
  src: url("../../../assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "PoppinsMedium";
  src: url("../../../assets/fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "PoppinsSemiBold";
  src: url("../../../assets/fonts/Poppins-SemiBold.ttf");
}

#password-recovery-page {
  background-color: #f2eff6;
}

#password-recovery-page .card-container {
  z-index: 1;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
}

#password-recovery-page .logo {
  width: 15vw;
  cursor: pointer;
}

#password-recovery-page .card-title {
  font-family: "PoppinsSemiBold";
  font-size: 2vw;
  margin-top: 5%;
}

#password-recovery-page .card-subtitle {
  font-family: "PoppinsRegular";
  margin-top: 10%;
  text-align: justify;
  text-justify: distribute-all-lines;
}

#password-recovery-page .input-labels {
  font-family: "PoppinsRegular";
  margin-top: 3%;
}

#password-recovery-page .signup-inputs {
  height: 5vh;
}

#password-recovery-page .agree-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32.5px;
  cursor: pointer;
  margin-bottom: 5%;
}

#password-recovery-page .agree-button-text {
  font-family: "PoppinsMedium";
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

#password-recovery-page .agree-button-container {
  margin-top: 5%;
}

#password-recovery-page .yoga-3 {
  width: 35%;
}

@media screen and (max-width: 575px) {
  #password-recovery-page .logo {
    width: 30vw;
  }

  #password-recovery-page .card-title {
    font-size: 4vw;
  }

  #password-recovery-page .card-container {
    z-index: 1;
    width: 90%;
    margin-bottom: 20%;
  }

  #password-recovery-page .card-subtitle {
    font-size: 2.5vw;
  }

  #password-recovery-page .agree-button {
    width: 40vw;
    height: 5vh;
    font-size: 2.5vw;
  }

  #password-recovery-page .yoga-3 {
    width: 60%;
  }

  #password-recovery-page .input-labels {
    font-size: 3vw;
  }
}

@media screen and (min-width: 576px) {
  #password-recovery-page .logo {
    width: 20vw;
  }

  #password-recovery-page .card-title {
    font-size: 3vw;
  }

  #password-recovery-page .card-subtitle {
    font-size: 1.9vw;
  }

  #password-recovery-page .card-container {
    z-index: 1;
    width: 80%;
    margin-bottom: 20%;
  }

  #password-recovery-page .agree-button {
    width: 23vw;
    height: 5vh;
    font-size: 1.6vw;
  }

  #password-recovery-page .yoga-3 {
    width: 50%;
  }

  #password-recovery-page .input-labels {
    font-size: 2vw;
  }
}

@media screen and (min-width: 992px) {
  #password-recovery-page .logo {
    width: 15vw;
  }

  #password-recovery-page .card-title {
    font-size: 2vw;
  }

  #password-recovery-page .card-subtitle {
    font-size: 1vw;
  }

  #password-recovery-page .card-container {
    z-index: 1;
    width: 37vw;
    margin-bottom: 0;
  }

  #password-recovery-page .agree-button {
    width: 15vw;
    height: 6vh;
    font-size: 1.1vw;
  }

  #password-recovery-page .yoga-3 {
    width: 27vw;
    margin-left: 0%;
  }

  #password-recovery-page .input-labels {
    font-size: 1.1vw;
  }
}

@media screen and (min-width: 1700px) {
  #password-recovery-page .card-subtitle {
    font-size: 0.9vw;
  }
}

@media screen and (min-width: 2000px) {
  #password-recovery-page .logo {
    width: 15vw;
  }

  #password-recovery-page .card-title {
    font-size: 2vw;
  }

  #password-recovery-page .card-container {
    z-index: 1;
  }

  #password-recovery-page .agree-button {
    width: 10vw;
    height: 67px;
    font-size: 1.1vw;
  }

  #password-recovery-page .yoga-3 {
    margin-left: -25%;
  }

  #password-recovery-page .input-labels {
    font-size: 1vw;
  }
}

@media screen and (min-width: 3000px) {
  #password-recovery-page .logo {
    width: 15vw;
  }

  #password-recovery-page .card-title {
    font-size: 2vw;
  }

  #password-recovery-page .card-container {
    z-index: 1;
  }

  #password-recovery-page .agree-button {
    width: 10vw;
    height: 67px;
    font-size: 1.1vw;
  }

  #password-recovery-page .yoga-3 {
    margin-left: -55%;
  }

  #password-recovery-page .input-labels {
    font-size: 1vw;
  }
}
