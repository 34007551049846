.modal-internal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.modal-internal.show {
  opacity: 1;
  pointer-events: visible;
  z-index: 5;
}

.modal-content {
  background-color: white;
  transform: translateY(-300px);
  transition: all 0.3s ease-in-out;
}

.modal-internal.show .modal-internal.content {
  transform: translateY(0);
}

/*.modal-internal-header,
.modal-internal-footer {
  padding: 10px;
}*/

.modal-internal-title {
  margin: 0;
}

.modal-internal-body {
  padding: 10px;
  border-top: 0px solid rgb(238, 238, 238, 0);
  border-bottom: 0px solid rgba(238, 238, 238, 0);
}

.modal-internal.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.modal-internal.exit {
  opacity: 0;
}

.modal-internal.enter-done .modal-content {
  transform: translateY(0);
}

.modal-internal.exit .modal-content {
  transform: translateY(-200px);
}

#modal-close-button {
  width: 5rem;
  height: 2rem;
  background-color: rgba(254, 204, 167, 1);
  font-size: 1rem;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  display: inline-block;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  margin: 0 0.25em 0 0;
  text-transform: none;
  font-weight: 700;
  text-align: center;
  border: 0px;
  border-radius: 0.28571429rem;
}

.dropdown-component__value-container {
  overflow-y: auto !important;
  max-height: 10rem;
}

@keyframes slide {
  0% {
    transform: translateY(-100%);
  }
  20% {
    transform: translateY(-80%);
  }
  40% {
    transform: translateY(-60%);
  }
  60% {
    transform: translateY(-40%);
  }
  80% {
    transform: translateY(-20%);
  }
  100% {
    transform: translateY(0%);
  }
}
