.custom-tooltip {
  position: absolute;

  --custom-pixels: 0.8vw;

  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  z-index: 6;
  cursor: initial;
}

.custom-tooltip.on {
  display: block;
}

.custom-tooltip.off {
  display: none;
}

.custom-tooltip .custom-tooltip__arrow {
  position: absolute;

  border-color: transparent;
  border-right-color: transparent;
  border-style: solid;
}

.custom-tooltip.left .custom-tooltip__arrow {
  left: calc(var(--custom-pixels) * (-1));
  right: auto;

  border-width: var(--custom-pixels) var(--custom-pixels) var(--custom-pixels) 0;
  border-right-color: var(--custom-tooltip-background-color);
}

.custom-tooltip.top .custom-tooltip__arrow {
  bottom: calc(var(--custom-pixels) * (-1));
  top: auto;

  border-width: var(--custom-pixels) var(--custom-pixels) 0;
  border-top-color: var(--custom-tooltip-background-color);
}

.custom-tooltip.right .custom-tooltip__arrow {
  right: calc(var(--custom-pixels) * (-1));
  left: auto;

  border-width: var(--custom-pixels) 0 var(--custom-pixels) var(--custom-pixels);
  border-left-color: var(--custom-tooltip-background-color);
}

.custom-tooltip.bottom .custom-tooltip__arrow {
  top: calc(var(--custom-pixels) * (-1));
  bottom: auto;

  border-width: 0 var(--custom-pixels) var(--custom-pixels);
  border-bottom-color: var(--custom-tooltip-background-color);
}

/**/

.custom-tooltip.right .custom-tooltip__arrow.top,
.custom-tooltip.left .custom-tooltip__arrow.top {
  top: 30%;
  transform: translate(0, -50%);
}

.custom-tooltip.right .custom-tooltip__arrow.bottom,
.custom-tooltip.left .custom-tooltip__arrow.bottom {
  top: 70%;
  transform: translate(0, -50%);
}

.custom-tooltip.right .custom-tooltip__arrow.center,
.custom-tooltip.left .custom-tooltip__arrow.center {
  top: 50%;
  transform: translate(0, -50%);
}

/**/

.custom-tooltip.top .custom-tooltip__arrow.right,
.custom-tooltip.bottom .custom-tooltip__arrow.right {
  left: 85%;
  transform: translate(-50%, 0);
}

.custom-tooltip.top .custom-tooltip__arrow.left,
.custom-tooltip.bottom .custom-tooltip__arrow.left {
  left: 15%;
  transform: translate(-50%, 0);
}

.custom-tooltip.top .custom-tooltip__arrow.center,
.custom-tooltip.bottom .custom-tooltip__arrow.center {
  left: 50%;
  transform: translate(-50%, 0);
}

.custom-tooltip .custom-tooltip__inner {
  padding: var(--custom-vertical-padding) var(--custom-horizontal-padding);

  color: var(--custom-tooltip-color);
  background-color: var(--custom-tooltip-background-color);
  border-radius: 10px;

  max-width: max-content;
}

.custom-tooltip .custom-tooltip__close-button {
  position: absolute;
  top: var(--custom-vertical-padding);
  right: var(--custom-horizontal-padding);

  width: 1.2rem;
  cursor: pointer;
}

.progress-bar-tooltip-container .custom-tooltip__inner {
  width: 18rem;
}

@media screen and (max-width: 767px) {
  .progress-bar-tooltip-container .custom-tooltip__inner {
    font-size: 90%;
  }

  .progress-bar-tooltip-container .custom-tooltip__close-button {
    width: 0.8rem;
    top: 1.6rem;
    right: 2rem;
  }

  .progress-bar-tooltip-container .custom-tooltip {
    bottom: -50% !important;
  }

  .progress-bar-tooltip-container .custom-tooltip__inner {
    width: 12rem;
  }

  .custom-tooltip-text-progress-bar {
    font-size: 100%;
  }
}

@media screen and (min-width: 1600px) {
  .custom-glossary .custom-tooltip .custom-tooltip__inner {
    padding: 1.3vw 1.8vw;
    width: 17.5vw;
    border-radius: 0.9vw;
  }

  .custom-glossary .custom-tooltip .custom-tooltip__close-button {
    top: 1.3vw;
    right: 1.8vw;
    width: 1.2vw;
  }

  .custom-tooltip-text-progress-bar {
    font-size: 0.88vw;
  }
}
