#purpose-info {
  font-size: 105%;
}

.purpose-info__title {
  margin-bottom: 1.5rem;
}

@media (min-width: 992px) {
  #purpose-info__purpose-graph {
    margin-left: 5rem;
  }
}

@media (max-width: 767px) {
  #purpose-info__purpose-graph svg {
    height: 550;
  }
}

@media (max-width: 575px) {
  #purpose-info__purpose-graph svg {
    max-height: 400;
  }
}

@media screen and (min-width: 1200px) {
  .purpose-info__title {
    font-size: 1.5vw !important;
    line-height: 2.1vw;
    margin-bottom: 1vw !important;
  }

  .purpose-info__text {
    font-size: 1.07vw;
    line-height: 1.8vw;
  }

  #purpose-info__purpose-graph svg {
    width: 80vw;
    height: auto;
  }
}
