.webcam-container #capture-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 37.5px;
  background-color: var(--purple-1);
  color: white;
  font-family: "PoppinsMedium";
  cursor: pointer;
}

@media screen and (max-width: 575px) {
  .webcam-container #capture-button {
    width: 60vw;
    height: 10vw;
    margin-top: 3%;
    font-size: 2.5vw;
    margin-bottom: 3%;
  }
}

@media screen and (min-width: 576px) {
  .webcam-container #capture-button {
    width: 40vw;
    height: 5vw;
    margin-top: 3%;
    font-size: 1.5vw;
    margin-bottom: 3%;
  }
}

@media screen and (min-width: 992px) {
  .webcam-container #capture-button {
    width: 30vw;
    height: 3.5vw;
    margin-top: 3%;
    font-size: 1.3vw;
    margin-bottom: 3%;
  }
}
