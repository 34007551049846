#about-me-container {
  background-color: #fff;
  width: 100vw;
}

#about-me-container .navbar {
  margin-bottom: 0 !important;
}

#about-me-container #avatar-container {
  position: relative;
}

#about-me-container #first-rectangle {
  width: 100%;
  background-color: #f2eff6;
  position: relative;
}

#about-me-container #page-title {
  font-family: "PoppinsSemiBold";
}

#about-me-container #user-since-section {
  font-family: "PoppinsRegular";
}

#about-me-container #logout-button,
.modal-internal-body #logout-confirm-button,
.modal-internal-body .photo-modal-options-buttons,
#about-me-container #save-details-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 37.5px;
  background-color: #604586;
  color: white;
  font-family: "PoppinsMedium";
  cursor: pointer;
}

#about-me-container #delete-account-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32.5px;
  border: 1px solid #cdbae7;
  font-family: "PoppinsMedium";
  color: #cdbae7;
  cursor: pointer;
}

#about-me-container #second-rectangle {
  width: 100% !important;
}

#about-me-container .info-field-name {
  font-family: "PoppinsSemiBold";
}

#about-me-container .info-field-value {
  font-family: "PoppinsRegular";
}

#about-me-container .edit-button {
  text-decoration: underline;
  font-family: "PoppinsRegular";
  color: #604586;
  cursor: pointer;
}

#about-me-container #edit-image {
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}

#about-me-container #modal-options-container {
  background-color: black;
  position: relative;
  z-index: 5;
}

.modal-internal-body #logout-cancel-button,
.modal-internal-body #photo-modal-cancel-button,
#about-me-container #cancel-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32.5px;
  border: 1px solid #604586;
  color: #604586;
  font-family: "PoppinsMedium";
  cursor: pointer;
}

.modal-internal-body #close-icon {
  position: absolute;
}

.modal-internal-body #photo-modal-title {
  font-family: "PoppinsSemiBold";
}

#about-me-container .input-container,
#about-me-container .input-container-big {
  left: 0;
  background-color: #f2eff6;
  width: 100vw;
  position: absolute;
}

#about-me-container .edit-inputs {
  position: relative;
  z-index: 1;
}

#about-me-container #edit-info-label {
  font-family: "PoppinsRegular";
}

#about-me-container .error-labels-about-me {
  font-family: "PoppinsRegular";
}

.modal-internal-body #avatar-image-editor-container {
  z-index: 7;
  position: relative;
}

@media screen and (max-width: 575px) {
  #about-me-container #avatar-container {
    margin-bottom: 2%;
    margin-top: 12%;
  }

  #about-me-container #page-title {
    font-size: 4.5vw;
  }

  #about-me-container #user-since-section {
    font-size: 1.6vw;
    margin-bottom: 14%;
  }

  #about-me-container #info-fields-container {
    margin-top: 10%;
  }

  #about-me-container .info-field {
    width: 80vw;
    margin-bottom: 5%;
  }

  #about-me-container .info-field-name {
    font-size: 3.5vw;
  }

  #about-me-container .info-field-value {
    font-size: 3vw;
    margin-top: 1%;
  }

  #about-me-container .edit-button {
    font-size: 3vw;
  }

  #about-me-container #logout-button,
  #about-me-container #delete-account-button {
    width: 40vw;
    height: 8vw;
    font-size: 2.4vw;
  }

  #about-me-container #bottom-buttons-container {
    margin-top: 5%;
    margin-bottom: 5%;
    height: 18vw;
  }

  #about-me-container .bottom-image {
    width: 50vw;
  }

  #about-me-container #bottom-image-container {
    width: 100%;
  }

  #about-me-container #edit-image {
    width: 5vw;
    height: 5vw;
  }

  #about-me-container #pencil-image {
    width: 2.5vw;
  }

  .modal-internal-body #logout-question {
    font-family: "PoppinsSemiBold";
    font-size: 4.5vw;
    width: 50%;
    margin-left: 4.5%;
  }

  .modal-internal-body #logout-description {
    font-family: "PoppinsRegular";
    font-size: 2.1vw;
    margin-top: 4%;
    margin-left: 4.5%;
  }

  .modal-internal-body #logout-cancel-button,
  .modal-internal-body #logout-confirm-button {
    width: 35vw;
    height: 8.5vw;
  }

  .modal-internal-body #buttons-container {
    font-size: 3vw;
    margin-top: 5%;
    width: 100%;
    height: 20vw;
  }

  .modal-internal-body #close-icon {
    position: absolute;
    top: 2vw;
    right: 2vw;
    cursor: pointer;
  }

  .modal-internal-body #photo-modal-title {
    font-size: 3.5vw;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .modal-internal-body #photo-modal-cancel-button,
  .modal-internal-body .photo-modal-options-buttons {
    width: 60vw;
    height: 10vw;
    font-size: 2.5vw;
  }

  .modal-internal-body #photo-modal-buttons-container {
    height: 50vw;
    margin-bottom: 5%;
  }
  .modal-internal-body #photo-modal-cancel-button {
    margin-top: 3%;
  }

  #about-me-container .input-container {
    height: 22vw;
  }

  #about-me-container .input-container-big {
    height: 40vw;
  }

  #about-me-container .edit-inputs {
    margin-bottom: 3%;
  }

  #about-me-container .edit-info-input {
    border: 0.1px solid #c2c0c7;
    border-radius: 3px;
    height: 6vw;
    width: 80vw;
    font-size: 2.8vw;
    font-family: "PoppinsRegular";
  }

  #about-me-container #edit-info-label {
    font-size: 2.5vw;
    margin-top: 3%;
  }

  #about-me-container #cancel-button,
  #about-me-container #save-details-button {
    width: 16vw;
    height: 5vw;
  }

  #about-me-container #edit-inputs-buttons-container {
    margin-top: 3%;
  }

  #about-me-container #container-inside-buttons-container {
    width: 45%;
    font-size: 1.8vw;
  }

  #about-me-container .date-picker-clear-indicator {
    width: 4.5vw;
    height: 4.5vw;
  }

  .modal-internal-body #avatar-image-editor-container {
    margin-bottom: 3%;
  }

  #about-me-container .error-labels-about-me {
    font-size: 1.5vw;
  }
}

@media screen and (min-width: 576px) {
  #about-me-container #avatar-container {
    margin-bottom: 2%;
    margin-top: 8%;
  }

  #about-me-container #page-title {
    font-size: 4vw;
  }

  #about-me-container #user-since-section {
    font-size: 1.5vw;
    margin-bottom: 8%;
  }

  #about-me-container #info-fields-container {
    margin-top: 10%;
  }

  #about-me-container .info-field {
    width: 75vw;
    margin-bottom: 5%;
  }

  #about-me-container .info-field-name {
    font-size: 2.4vw;
  }

  #about-me-container .info-field-value {
    font-size: 2vw;
    margin-top: 1%;
  }

  #about-me-container .edit-button {
    font-size: 2vw;
  }

  #about-me-container #logout-button,
  #about-me-container #delete-account-button {
    width: 30vw;
    height: 7vw;
    font-size: 1.8vw;
  }

  #about-me-container #bottom-buttons-container {
    margin-top: 5%;
    margin-bottom: 5%;
    height: 16vw;
  }

  #about-me-container .bottom-image {
    width: 40vw;
  }

  #about-me-container #bottom-image-container {
    width: 100%;
  }

  #about-me-container #edit-image {
    width: 4vw;
    height: 4vw;
  }

  #about-me-container #pencil-image {
    width: 2vw;
  }

  .modal-internal-body #logout-question {
    font-family: "PoppinsSemiBold";
    font-size: 2.5vw;
    width: 40%;
    margin-left: 4%;
  }

  .modal-internal-body #logout-description {
    font-family: "PoppinsRegular";
    font-size: 1.4vw;
    margin-top: 3%;
    margin-left: 4%;
  }

  .modal-internal-body #logout-cancel-button,
  .modal-internal-body #logout-confirm-button {
    width: 15vw;
    height: 5vw;
  }

  .modal-internal-body #buttons-container {
    font-size: 1.5vw;
    margin-top: 5%;
    width: 100%;
    height: auto;
  }

  .modal-internal-body #close-icon {
    position: absolute;
    top: 2vw;
    right: 2vw;
    cursor: pointer;
  }

  .modal-internal-body #photo-modal-title {
    font-size: 2.5vw;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .modal-internal-body #photo-modal-cancel-button,
  .modal-internal-body .photo-modal-options-buttons {
    width: 40vw;
    height: 5vw;
    font-size: 1.5vw;
  }

  .modal-internal-body #photo-modal-buttons-container {
    height: 25vw;
    margin-bottom: 5%;
  }
  .modal-internal-body #photo-modal-cancel-button {
    margin-top: 3%;
  }

  #about-me-container .input-container {
    height: 15vw;
  }

  #about-me-container .input-container-big {
    height: 32vw;
  }

  #about-me-container .edit-inputs {
    margin-bottom: 3%;
  }

  #about-me-container .edit-info-input {
    border: 0.1px solid #c2c0c7;
    border-radius: 3px;
    height: 3vw;
    width: 75vw;
    font-size: 1.3vw;
    font-family: "PoppinsRegular";
  }

  #about-me-container #edit-info-label {
    font-size: 1.3vw;
    margin-top: 3%;
  }

  #about-me-container #cancel-button,
  #about-me-container #save-details-button {
    width: 13vw;
    height: 4vw;
  }

  #about-me-container #edit-inputs-buttons-container {
    margin-top: 3%;
  }

  #about-me-container #container-inside-buttons-container {
    width: 45%;
    font-size: 1.2vw;
  }

  #about-me-container .date-picker-clear-indicator {
    width: 2.5vw;
    height: 2.5vw;
  }

  .modal-internal-body #avatar-image-editor-container {
    margin-bottom: 3%;
  }

  #about-me-container .error-labels-about-me {
    font-size: 1vw;
  }
}

@media screen and (min-width: 992px) {
  #about-me-container #first-rectangle {
    height: 25vw;
  }

  #about-me-container #avatar-container {
    margin-bottom: 2%;
    margin-top: 8%;
  }

  #about-me-container #page-title {
    font-size: 2.7vw;
  }

  #about-me-container #user-since-section {
    font-size: 0.9vw;
    margin-bottom: 8%;
  }

  #about-me-container #info-fields-container {
    margin-top: 10%;
  }

  #about-me-container .info-field {
    width: 60vw;
    margin-bottom: 5%;
  }

  #about-me-container .info-field-name {
    font-size: 1.3vw;
  }

  #about-me-container .info-field-value {
    font-size: 1.1vw;
    margin-top: 1%;
  }

  #about-me-container .edit-button {
    font-size: 1vw;
  }

  #about-me-container #logout-button,
  #about-me-container #delete-account-button {
    width: 22vw;
    height: 4.5vw;
    font-size: 1.3vw;
  }

  #about-me-container #bottom-buttons-container {
    margin-top: 5%;
    margin-bottom: 5%;
    height: 11vw;
  }

  #about-me-container .bottom-image {
    width: 25vw;
  }

  #about-me-container #bottom-image-container {
    width: 60%;
  }

  #about-me-container #edit-image {
    width: 2.5vw;
    height: 2.5vw;
  }

  #about-me-container #pencil-image {
    width: 1vw;
  }

  .modal-internal-body #logout-question {
    font-family: "PoppinsSemiBold";
    font-size: 1.8vw;
    width: 40%;
    margin-left: 4%;
  }

  .modal-internal-body #logout-description {
    font-family: "PoppinsRegular";
    font-size: 1vw;
    width: 86%;
    margin-top: 3%;
    margin-left: 4%;
  }

  .modal-internal-body #logout-cancel-button,
  .modal-internal-body #logout-confirm-button {
    width: 12vw;
    height: 3.5vw;
  }

  .modal-internal-body #buttons-container {
    font-size: 1vw;
    margin-top: 5%;
    width: 100%;
  }

  .modal-internal-body #close-icon {
    position: absolute;
    top: 1vw;
    right: 1vw;
    cursor: pointer;
  }

  .modal-internal-body #photo-modal-title {
    font-size: 1.3vw;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .modal-internal-body #photo-modal-cancel-button,
  .modal-internal-body .photo-modal-options-buttons {
    width: 30vw;
    height: 3.5vw;
    font-size: 1vw;
  }

  .modal-internal-body #photo-modal-buttons-container {
    height: 18vw;
    margin-bottom: 5%;
  }

  .modal-internal-body #photo-modal-cancel-button {
    margin-top: 3%;
  }

  #about-me-container .input-container {
    height: 12vw;
  }

  #about-me-container .input-container-big {
    height: 26vw;
  }

  #about-me-container .edit-inputs {
    margin-bottom: 3%;
  }

  #about-me-container .edit-info-input {
    border: 0.1px solid #c2c0c7;
    border-radius: 3px;
    height: 2.5vw;
    width: 60vw;
    background-color: white;
    font-family: "PoppinsRegular";
    font-size: 1vw;
  }

  #about-me-container #dropdownMenuButton {
    width: 60vw;
    height: 2.5vw;
    padding-right: 1%;
    padding-left: 1%;
  }

  #about-me-container #edit-info-label {
    font-size: 1vw;
    margin-top: 3%;
  }

  #about-me-container #cancel-button,
  #about-me-container #save-details-button {
    width: 10vw;
    height: 3vw;
  }

  #about-me-container #edit-inputs-buttons-container {
    margin-top: 3%;
  }

  #about-me-container #container-inside-buttons-container {
    width: 35%;
    font-size: 0.9vw;
  }

  .modal-internal-body #avatar-image-editor-container {
    margin-bottom: 3%;
  }

  #about-me-container .error-labels-about-me {
    font-size: 0.8vw;
  }

  #about-me-container .date-picker-clear-indicator {
    width: 2.5vw;
    height: 2vw;
  }
}
