@media screen and (max-width: 991px) {
  .results-radar-graph {
    width: 70vw;
  }

  .results-graph-image .tooltip-new .custom-tooltip .custom-tooltip__inner {
    width: 25vw;
  }
}

@media screen and (max-width: 767px) {
  .results-radar-graph {
    width: 85vw;
  }

  .results-graph-image .tooltip-new .custom-tooltip .custom-tooltip__inner {
    width: 25vw;
  }
}

@media screen and (max-width: 575px) {
  .results-radar-graph {
    width: 100vw;
  }

  .results-graph-image .tooltip-new .custom-tooltip .custom-tooltip__inner {
    width: 22vw;
  }
}

@media screen and (min-width: 992px) {
  .results-radar-graph {
    width: 45vw;
  }

  .results-graph-image .tooltip-new .custom-tooltip .custom-tooltip__inner {
    width: 18vw;
  }
}

/*
.radar-graph-tooltip .custom-tooltip .custom-tooltip__inner {
  text-align: center;
}

.radar-graph-tooltip .custom-tooltip {
  display: none !important;
}

.radar-graph-quadrant-circle:hover + .radar-graph-tooltip .custom-tooltip {
  display: block !important;
}
*/
