#radar-graph-info {
  position: relative;
  display: grid;
  color: var(--main-text-1);
  line-height: 1.5rem;
}

#radar-graph-info__radar {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.dimension-wellbeing__image {
  margin-bottom: 1.5rem;
}

.dimension-wellbeing__title {
  margin-bottom: 1rem;
}

.dimension-wellbeing__text {
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 1200px) {
  .radar-graph-info__title {
    font-size: 1.9vw;
    line-height: 3vw;
    margin-bottom: 1.8vw !important;
  }

  .dimension-wellbeing__image {
    margin-bottom: 1.5vw;
  }

  .dimension-wellbeing__title {
    font-size: 1.4vw;
    line-height: 2.8vw;
    margin-bottom: 1vw;
  }

  .dimension-wellbeing__text {
    font-size: 1vw;
    line-height: 1.5vw;
    margin-bottom: 1.5vw;
  }

  .dimension-wellbeing__image svg {
    width: 6vw !important;
    height: auto !important;
  }
}
