#results-page-template #score-display {
  position: absolute;
  top: 13.5rem;
  width: 100vw;
  z-index: 1;

  display: flex;
  justify-content: center;

  transform: scale(1.06);
}
