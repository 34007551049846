@font-face {
  font-family: "PoppinsSemiBold";
  src: url("../../../../assets/fonts/Poppins-SemiBold.ttf");
}

#new-question-content {
  font-family: "PoppinsSemiBold";
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--main-text-1);
  padding: 0 !important;
}

#new-question-content-core {
  font-family: "PoppinsSemiBold";
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--main-text-1);
  padding: 0 !important;
}

#greetings-container {
  font-family: "PoppinsSemiBold";
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 0 !important;
  color: var(--purple-1);
}

@media screen and (max-width: 575px) {
  #new-question-content {
    font-size: 7vw;
    width: 85vw;
  }

  #new-question-content-core {
    font-size: 7vw;
    width: 85vw;
    height: 40vw;
  }

  #greetings-container {
    margin-top: 0;
    font-size: 3vw;
    width: 85vw;
  }
}

@media screen and (min-width: 576px) {
  #new-question-content {
    font-size: 5vw;
    width: 80vw;
  }

  #new-question-content-core {
    font-size: 5vw;
    width: 80vw;
    height: 40vw;
  }

  #greetings-container {
    margin-top: 0;
    font-size: 2vw;
    width: 80vw;
  }
}

@media screen and (min-width: 768px) {
  #new-question-content {
    font-size: 3.5vw;
  }

  #new-question-content-core {
    font-size: 3vw;
    height: 30vw;
  }
}

@media screen and (min-width: 992px) {
  #new-question-content {
    margin-top: 2%;
    font-size: 2.2vw;
    width: 60vw;
  }

  #new-question-content-core {
    margin-top: 0;
    font-size: 2.2vw;
    width: 60vw;
    height: 6vw;
  }

  #greetings-container {
    margin-top: 0;
    font-size: 1vw;
    width: 60vw;
  }
}
