.purpose-graph__indicator-positive {
  animation: purpose-graph__indicator-positive-animation 2s forwards;
  transform-origin: 275.334px 197.44px;
}

.purpose-graph__indicator-negative {
  animation: purpose-graph__indicator-negative-animation 2s forwards;
  transform-origin: 275.334px 197.44px;
}

@keyframes purpose-graph__indicator-positive-animation {
  0% {
    transform: translate(0, 77.06px) rotate(180deg);
  }

  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

@keyframes purpose-graph__indicator-negative-animation {
  0% {
    transform: translate(0, 77.06px) rotate(0deg);
  }

  100% {
    transform: translate(0, calc(77.06px * 2)) rotate(180deg);
  }
}
