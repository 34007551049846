@media (max-width: 991px) {
  .custom-glossary .regular-modal.on {
    top: 100%;
    transform: translate(-50%, -100%);
    width: 100vw;
    font-size: 90%;
  }
}

@media (max-width: 991px) {
  .custom-glossary .glossary-icon-container {
    width: 5rem;
  }
}

@media (min-width: 992px) {
  .custom-glossary .glossary-icon-container {
    width: 5.5vw;
  }
}

@media screen and (min-width: 1600px) {
  .custom-glossary-tooltip-title {
    font-size: 1.5vw;
  }

  .custom-glossary-tooltip-body {
    font-size: 0.88vw;
  }
}

@media screen and (max-width: 1600px) {
  .custom-tooltip__inner {
    width: 20rem;
  }
}

@media screen and (max-width: 767px) {
  .custom-glossary .custom-glossary-tooltip-title {
    font-size: calc((1.275rem * 90 / 100 + 0.3vw * 90 / 100));
  }

  .custom-glossary .custom-glossary-tooltip-body {
    font-size: 90%;
  }

  .custom-glossary .custom-tooltip__inner {
    width: 18rem;
  }
}
