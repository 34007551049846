@font-face {
  font-family: "PoppinsBold";
  src: url("../../assets/fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: "PoppinsRegular";
  src: url("../../assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "PoppinsMedium";
  src: url("../../assets/fonts/Poppins-Medium.ttf");
}

.header .top-rectangle {
  height: 9vw;
  background-color: white;
  z-index: 5;
}

.header .logo {
  object-fit: contain;
  position: relative;
  z-index: 10;
}

.header #invite-others {
  font-family: "PoppinsRegular";
  font-size: 1vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: normal;
  color: var(--main-text-1);
}

.header #icons-container {
  display: flex;
  justify-content: space-around;
}

.header .vertical-line {
  background-color: #dadada;
}

.header .header-button {
  font-family: "PoppinsMedium";
  font-size: 1vw;
  font-weight: 500;
  line-height: 1.39;
  color: var(--main-text-1);
  cursor: pointer;
}

.header .Login,
.header .myAnalytics {
  white-space: nowrap;
}

.header .Profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header .Main-Button-Fill-Avenir-Desktop-signup {
  width: 13.4%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 32.5px;
  background-color: var(--purple-1);
  cursor: pointer;
}

.header #Sign-up-button {
  width: 40%;
  flex-grow: 0;
  font-family: "PoppinsMedium";
  font-size: 1vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.header .purple-page-selector {
  background-color: #604586;
  position: absolute;
  bottom: 0;
}

@media screen and (max-width: 768px) {
  .header .right-side-header {
    visibility: hidden;
  }

  .header .logo {
    margin-left: 5%;
    width: 20.8%;
  }
}

@media screen and (min-width: 0px) {
  .header .right-side-header {
    visibility: hidden;
  }

  .header .logo {
    margin-left: 5%;
    width: 30.8%;
  }

  .header .top-rectangle {
    height: 18vw;
  }

  .header #languages-container {
    margin-top: 6.5%;
    font-size: 3vw;
    margin-left: -33%;
  }
}

@media screen and (min-width: 576px) {
  .header .right-side-header {
    visibility: hidden;
  }

  .header .top-rectangle {
    height: 14vw;
  }

  .header .logo {
    margin-left: 5%;
    width: 25.8%;
  }

  .header #languages-container {
    margin-top: 6%;
    font-size: 1.8vw;
    margin-left: -50%;
  }
}

@media screen and (min-width: 768px) {
  .header .right-side-header {
    visibility: hidden;
  }

  .header .top-rectangle {
    height: 12vw;
  }

  .header .logo {
    margin-left: 5%;
    width: 20.8%;
  }

  .header #languages-container {
    margin-top: 4.5%;
    font-size: 1.6vw;
    margin-left: -60%;
  }
}

@media screen and (min-width: 992px) {
  .header .right-side-header {
    visibility: visible !important;
  }

  .header .top-rectangle {
    height: 8vw;
  }

  .header .logo {
    width: 11.8%;
    height: 53%;
    margin-left: 5%;
    margin-right: 1%;
  }

  .header .Main-Button-Fill-Avenir-Desktop-signup {
    width: 20.4%;
    height: 3vw;
    margin-right: 0%;
  }

  .header .Profile {
    width: 20.4%;
    height: 6.4vh;
    margin-right: 0%;
  }

  .header .Login {
    margin-right: 5%;
  }

  .header .Meet-violet {
    margin-right: 5%;
    white-space: nowrap;
  }

  .header .vertical-line {
    height: 5vw;
    margin-right: 5%;
    margin-left: 0%;
  }

  .header #icons-container {
    width: 100%;
  }

  .header .purple-page-selector {
    height: 0.2vw;
  }

  .header #analytics-selector {
    width: 6.5vw;
  }
  .header #meet-violet-selector {
    width: 6vw;
    margin-left: 0.3%;
  }
  .header #profile-selector {
    width: 7.1vw;
    margin-left: 1%;
  }

  .header #languages-container {
    margin-top: 2.5%;
    font-size: 1vw;
    margin-right: 0;
    margin-left: 0;
  }
}

@media screen and (min-width: 2000px) {
  .header .right-side-header {
    visibility: visible !important;
  }

  .header .top-rectangle {
    height: 10vw;
  }

  .header .logo {
    width: 12.8%;
    height: 53%;
    margin-left: 5%;
  }

  .header .Main-Button-Fill-Avenir-Desktop-signup {
    width: 20.4%;
    height: 4vw;
    margin-right: 5%;
  }

  .header .Profile {
    width: 20.4%;
    height: 4vw;
    margin-right: 5%;
  }

  .header .Login {
    margin-right: 5%;
  }

  .header .Meet-violet {
    margin-right: 5%;
  }

  .header .vertical-line {
    height: 5vw;
    margin-right: 5%;
    margin-left: 5%;
  }

  .header #icons-container {
    width: 130%;
  }

  .header .share-icons-header {
    width: 100%;
  }
}

@media screen and (min-width: 3000px) {
  .header .right-side-header {
    visibility: visible !important;
  }

  .header .logo {
    width: 12.8%;
    height: 53%;
    margin-left: 5%;
  }

  .header .Main-Button-Fill-Avenir-Desktop-signup {
    height: 4vw;
    border-radius: 50px;
  }

  .header .Profile {
    width: 20.4%;
    height: 6.4vh;
    margin-right: 5%;
  }

  .header .Login {
    margin-right: 5%;
  }

  .header .Meet-violet {
    margin-right: 5%;
  }

  .header .vertical-line {
    height: 5vw;
    margin-right: 5%;
    margin-left: 5%;
  }

  .header #icons-container {
    width: 170%;
  }

  .header .share-icons-header {
    width: 100%;
  }

  .header #invite-others {
    width: 170%;
  }
}
