#new-core-survey-page .top-container {
  margin-top: 7%;
}

#new-core-survey-page #progress-bar-container {
  display: flex;
  justify-content: center;
}

#new-core-survey-page #progress-bar {
  border-radius: 10px;
  background-color: #efedf1;
  margin-right: 2vw;
  margin-left: 2vw;
}

#new-core-survey-page #progress-indicator {
  border-radius: 15px;
  background-color: var(--purple-1);
}

#new-core-survey-page #go-back-icon {
  object-fit: contain;
  cursor: pointer;
}

#new-core-survey-page #go-forward-icon {
  object-fit: contain;
  margin-left: 0.5vw;
  cursor: pointer;
}

#new-core-survey-page .custom-glossary {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 3%;

  width: 95%;
}

#new-core-survey-page .Submit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32.5px;
  background-color: #c2c0c7;
  font-family: "PoppinsMedium";
  color: white;
  cursor: pointer;
}

@media screen and (max-width: 575px) {
  #new-core-survey-page .top-container {
    width: 90% !important;
  }

  #new-core-survey-page #progress-bar {
    width: 100vw;
  }

  #new-core-survey-page #progress-bar-container {
    margin-top: 5%;
    margin-bottom: 10%;
  }

  #new-core-survey-page .Submit-button {
    width: 33vw;
    height: 6vh;
    margin-top: 20%;
    font-size: 2.7vw;
  }

  #new-core-survey-page #go-back-icon,
  #new-core-survey-page #go-forward-icon {
    width: 8vw;
  }

  #new-core-survey-page #progress-bar {
    height: 3vw;
  }

  #new-core-survey-page #progress-indicator {
    height: 3vw;
  }
}

@media screen and (min-width: 576px) {
  #new-core-survey-page .top-container {
    width: 80% !important;
  }

  #new-core-survey-page #progress-bar {
    width: 100vw;
  }

  #new-core-survey-page #progress-bar-container {
    margin-top: 3%;
    margin-bottom: 10%;
  }

  #new-core-survey-page .Submit-button {
    width: 24vw;
    height: 6vh;
    margin-top: 20%;
    font-size: 2.2vw;
  }

  #new-core-survey-page #go-back-icon,
  #new-core-survey-page #go-forward-icon {
    width: 6vw;
  }

  #new-core-survey-page #progress-bar {
    height: 2vw;
  }

  #new-core-survey-page #progress-indicator {
    height: 2vw;
  }
}

@media screen and (min-width: 992px) {
  #new-core-survey-page .top-container {
    width: 60% !important;
  }

  #new-core-survey-page #progress-bar {
    width: 85%;
  }

  #new-core-survey-page .custom-glossary {
    margin-top: -5%;
    margin-bottom: 0;
    padding-bottom: 10%;
  }

  #new-core-survey-page #go-back-icon {
    display: block;
  }

  #new-core-survey-page #go-forward-icon {
    display: block;
  }

  #new-core-survey-page #progress-bar-container {
    margin-top: 2%;
  }

  #new-core-survey-page .Submit-button {
    width: 13vw;
    height: 8vh;
    font-size: 1.2vw;
    margin-top: 0;
  }

  #new-core-survey-page #go-back-icon,
  #new-core-survey-page #go-forward-icon {
    width: 4vw;
  }

  #new-core-survey-page #progress-bar {
    height: 1vw;
  }

  #new-core-survey-page #progress-indicator {
    height: 1vw;
  }
}
