/* Removes the clear button from date inputs */
.custom-native-date-picker::-webkit-clear-button {
  display: none;
}

/* Removes the spin button */
.custom-native-date-picker::-webkit-inner-spin-button {
  display: none;
}

/* Always display the drop down caret */
.custom-native-date-picker::-webkit-calendar-picker-indicator {
  color: #2c3e50;
}

/* A few custom styles for date inputs */
.custom-native-date-picker {
  /*
  appearance: none;
  -webkit-appearance: none;
  */
  color: #95a5a6;
  font-size: 18px;
  border: 1px solid #ecf0f1;
  background: #ecf0f1;
  padding: 5px;
  display: flex !important;
  visibility: visible !important;
  font-family: "PoppinsRegular";
  align-items: center;

  appearance: textfield;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
}

.safari-mobile-datepicker {
  display: flex;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
}

::-webkit-date-and-time-value {
  text-align: left !important;
}

.custom-native-date-picker,
focus {
  color: #95a5a6;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.custom-native-date-picker::placeholder {
  font-family: "PoppinsRegular";
  font-size: 10.5px;
  color: black;
}

.react-datepicker {
  display: none;
}

.react-datepicker__navigation {
  overflow: inherit;
}

.react-datepicker__navigation-icon {
  width: 100%;
}

.react-datepicker__navigation-icon--previous::before {
  left: 10px;
}

.react-datepicker__navigation-icon--next::before {
  right: 10px;
  left: auto;
}

.datepicker-header,
.datepicker-navbar-btn {
  color: var(--purple-1) !important;
}

.datepicker-modal {
  position: fixed !important;
}

.datepicker-wheel {
  border-top: 1px solid var(--purple-1) !important;
  border-bottom: 1px solid var(--purple-1) !important;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

@media screen and (max-width: 575px) {
  #safari-placeholder {
    font-size: 2vw;
  }
}

@media screen and (min-width: 576px) {
  #safari-placeholder {
    font-size: 1.3vw;
  }
}
