.btn.main-custom-button {
  --results-button-text-color: white;
  --results-button-background-color: var(--purple-1);
  --results-button-border: 0;

  background-color: var(--results-button-background-color);
  color: var(--results-button-text-color) !important;
  border: var(--results-button-border);
  border-radius: 32.5px;
  padding: 0.8rem 3rem;
  font-size: 0.9rem;
  font-family: PoppinsMedium;
}

.btn.main-custom-button.purple {
  --results-button-text-color: white;
  --results-button-background-color: var(--purple-1);
  --results-button-border: 0px;
  --results-button-hover: var(--hover-fill);
  --results-button-active: var(--pressed-fill);
}

.btn.main-custom-button.transparent {
  --results-button-text-color: var(--purple-1);
  --results-button-background-color: transparent;
  --results-button-border: 2px solid var(--purple-1);
  --results-button-hover: var(--hover-outline);
  --results-button-active: var(--survey-field-pressed);
}

.btn.main-custom-button:hover {
  background-color: var(--results-button-hover);
}

.btn.main-custom-button:active {
  background-color: var(--results-button-active);
}

.btn.main-custom-button:focus {
  outline: none;
  box-shadow: none;
}

@media screen and (min-width: 1200px) {
  .btn.main-custom-button {
    font-size: 1vw;
    border-radius: 2vw;
    padding: 1vw 3.6vw;
  }
}
