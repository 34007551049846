#results-page-template {
  width: 100vw;
}
.zone-and-score,
.focus-and-evolution,
#radar-graph-info,
#purpose-info,
#tips-info,
#compare-with-others,
#share-info {
  line-height: 1.8rem;
  color: var(--main-text-1);
}

.zone-and-score,
.focus-and-evolution,
#radar-graph-info,
#purpose-info,
#tips-info,
#compare-with-others,
#share-info {
  padding: 7vw 0;
}

@media screen and (max-width: 576px) {
  .zone-and-score,
  .focus-and-evolution,
  #radar-graph-info,
  #purpose-info,
  #tips-info,
  #compare-with-others,
  #share-info {
    padding: 14vw 0 !important;
  }
}
