.retention-of-personal-data-table,
.retention-of-personal-data-table tr,
.retention-of-personal-data-table td,
.retention-of-personal-data-table th {
  border: 1px solid black;
}

.retention-of-personal-data-table th,
.retention-of-personal-data-table td {
  padding: 0.5rem 1rem;
}
