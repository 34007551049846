#admin-interface-container .navigation-link {
  cursor: pointer;
}

#admin-interface-container #period-buttons-container {
  border-radius: 37.5px;
  background-color: #f2eff6;
  font-family: "PoppinsSemiBold";
  cursor: pointer;
}

#admin-interface-container .period-buttons {
  border-radius: 33.5px;
  background-color: #604586;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 575px) {
  #admin-interface-container .table-content-fonts {
    font-size: 1.7vw;
  }

  #admin-interface-container .custom-native-date-picker::placeholder {
    font-size: 1.5vw;
    color: hsl(0, 0%, 50%);
  }

  #admin-interface-container .navbar-text {
    font-size: 3vw;
  }

  #admin-interface-container .table-titles {
    font-size: 4vw;
  }

  #admin-interface-container .date-subtitles-analytics {
    font-size: 3vw;
    margin-bottom: 1vw;
  }

  #admin-interface-container .analytics-title {
    font-size: 4.5vw;
  }

  #admin-interface-container #period-buttons-description {
    font-size: 2.5vw;
    text-align: center;
    width: 80vw;
    margin-top: 4%;
    margin-bottom: 1%;
  }

  #admin-interface-container #period-buttons-container {
    width: 90vw;
    height: 7vw;
    font-size: 2vw;
  }

  #admin-interface-container .period-buttons {
    width: 20vw;
    height: 6vw;
  }
}

@media screen and (min-width: 576px) {
  #admin-interface-container .table-content-fonts {
    font-size: 2vw;
  }

  #admin-interface-container .custom-native-date-picker::placeholder {
    font-size: 1.3vw;
    color: hsl(0, 0%, 50%);
  }

  #admin-interface-container .navbar-text {
    font-size: 2vw;
  }

  #admin-interface-container .table-titles {
    font-size: 3vw;
  }

  #admin-interface-container .date-subtitles-analytics {
    font-size: 2vw;
    margin-bottom: 1vw;
  }

  #admin-interface-container .analytics-title {
    font-size: 3.5vw;
  }

  #admin-interface-container #period-buttons-description {
    font-size: 1.3vw;
    margin-top: 3%;
    width: auto;
    margin-bottom: 0;
  }

  #admin-interface-container #period-buttons-container {
    width: 80vw;
    height: 7vw;
    font-size: 2vw;
  }

  #admin-interface-container .period-buttons {
    width: 20vw;
    height: 6vw;
  }
}

@media screen and (min-width: 992px) {
  #admin-interface-container .table-content-fonts {
    font-size: 100%;
  }

  #admin-interface-container .custom-native-date-picker::placeholder {
    font-size: 1vw;
    color: hsl(0, 0%, 50%);
  }

  #admin-interface-container .navbar-text {
    font-size: 1.2vw;
  }

  #admin-interface-container .table-titles {
    font-size: 2vw;
  }

  #admin-interface-container .date-subtitles-analytics {
    font-size: 1.5vw;
    margin-bottom: 1vw;
  }

  #admin-interface-container .analytics-title {
    font-size: 2.5vw;
  }

  #admin-interface-container #period-buttons-description {
    font-size: 1vw;
    margin-top: 3%;
    width: auto;
    margin-bottom: 0;
  }

  #admin-interface-container #period-buttons-container {
    width: 80vw;
    height: 4vw;
    font-size: 1vw;
  }

  #admin-interface-container .period-buttons {
    width: 20vw;
    height: 3.5vw;
  }
}
