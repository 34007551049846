.preliminary-hide-fade {
  z-index: 1;

  grid-row-start: 1;
  grid-column-start: 1;
}

.preliminary-hide-modal-container {
  grid-row-start: 1;
  grid-column-start: 1;
}

.preliminary-hide-modal {
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  border-radius: 12.5px;
  padding: 5rem 0;
  z-index: 2;
  position: relative;
}
