@font-face {
  font-family: "PoppinsBold";
  src: url("../../../assets/fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: "PoppinsRegular";
  src: url("../../../assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "PoppinsMedium";
  src: url("../../../assets/fonts/Poppins-Medium.ttf");
}

#landing-page-container {
  background-color: #fff;
  height: 100vh;
  position: relative;
  z-index: 0;
}

#landing-page-container .Make-your-well-being-a-priority {
  font-family: "PoppinsBold";
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var(--main-text-1);
  line-height: 1.4 !important;
}

#landing-page-container .firstImage,
#landing-page-container .secondImage,
#landing-page-container .thirdImage {
  width: 44.5vw;
  object-fit: contain;
  margin-top: -5vh;
  margin-bottom: 5%;
  animation: autoplay2 8s;
  animation-iteration-count: infinite;
}

#landing-page-container .texto {
  font-family: "PoppinsRegular";
  font-size: 1.25vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var(--main-text-1);
}

#landing-page-container .Main-Button-Fill-Avenir-Desktop {
  width: 14.8vw;
  height: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32.5px;
  background-color: var(--purple-1);
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#landing-page-container .Main-Button-Outline-Avenir-Desktop {
  width: 14.8vw;
  height: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32.5px;
  border: solid 2px #604586;
  cursor: pointer;
}

#landing-page-container .Start-survey {
  width: 100%;
  flex-grow: 0;
  font-family: "PoppinsMedium";
  font-size: 1.1vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

#landing-page-container .Meet-violet-button {
  width: 100%;
  flex-grow: 0;
  font-family: "PoppinsMedium";
  font-size: 1.1vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--purple-1);
}

#landing-page-container .buttons-container {
  margin-top: 3%;
}

@keyframes autoplay2 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  33.33% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
}

@media screen and (max-width: 575px) {
  #landing-page-container .Make-your-well-being-a-priority {
    font-size: 6.5vw;
    width: 80%;
    text-align: center;
  }

  #landing-page-container .texto {
    font-size: 3.5vw;
    margin-bottom: 10%;
    text-align: center;
    width: 80%;
  }

  #landing-page-container .buttons-container {
    margin-top: 3%;
    margin-bottom: 20%;
    height: 30vw;
  }

  #landing-page-container .Main-Button-Outline-Avenir-Desktop {
    width: 50vw;
    height: 13vw;
  }

  #landing-page-container .Main-Button-Fill-Avenir-Desktop {
    width: 50vw;
    height: 13vw;
  }

  #landing-page-container .Start-survey {
    font-size: 2.8vw;
  }

  #landing-page-container .Meet-violet-button {
    font-size: 2.8vw;
  }

  #landing-page-container .firstImage,
  #landing-page-container .secondImage,
  #landing-page-container .thirdImage {
    width: 70vw;
    height: 60vw;
  }
}

@media screen and (min-width: 576px) {
  #landing-page-container .Make-your-well-being-a-priority {
    font-size: 4vw;
    width: 70%;
    text-align: center;
  }

  #landing-page-container .texto {
    font-size: 2vw;
    padding-left: 15%;
    padding-right: 15%;
    margin-bottom: 7%;
    text-align: center;
  }

  #landing-page-container .buttons-container {
    margin-top: 3%;
    margin-bottom: 20%;
    height: 0;
    margin-left: 0;
    width: 55%;
  }

  #landing-page-container .Main-Button-Outline-Avenir-Desktop {
    width: 25vw;
    height: 8vw;
  }

  #landing-page-container .Main-Button-Fill-Avenir-Desktop {
    width: 25vw;
    height: 8vw;
  }

  #landing-page-container .Start-survey {
    font-size: 1.8vw;
  }

  #landing-page-container .Meet-violet-button {
    font-size: 1.8vw;
  }

  #landing-page-container .firstImage,
  #landing-page-container .secondImage,
  #landing-page-container .thirdImage {
    width: 60vw;
    height: 50vw;
  }
}

@media screen and (min-width: 992px) {
  #landing-page-container .Make-your-well-being-a-priority {
    font-size: 2.5vw;
    width: 75%;
    text-align: unset;
  }

  #landing-page-container .texto {
    font-size: 1.2vw;
    width: 75%;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 7%;
    margin-top: 2%;
    text-align: unset;
    line-height: 2vw;
  }

  #landing-page-container .left-side-container {
    width: 65%;
  }

  #landing-page-container .image-container {
    width: 65%;
    height: 90%;
    margin-top: -3%;
  }

  #landing-page-container .buttons-container {
    margin-top: 4%;
    margin-bottom: 60%;
    height: 0;
    width: 75%;
  }

  #landing-page-container .Main-Button-Outline-Avenir-Desktop {
    width: 14.8vw;
    height: 4vw;
    margin-left: 10%;
  }

  #landing-page-container .Main-Button-Fill-Avenir-Desktop {
    width: 14.8vw;
    height: 4vw;
  }

  #landing-page-container .Start-survey {
    font-size: 1.1vw;
  }

  #landing-page-container .Meet-violet-button {
    font-size: 1.1vw;
  }

  #landing-page-container .firstImage,
  #landing-page-container .secondImage,
  #landing-page-container .thirdImage {
    width: 35vw;
    height: 50vw;
    margin-top: -10%;
  }
}

@media screen and (min-width: 1200px) {
  #landing-page-container .buttons-container {
    margin-top: 4%;
    margin-bottom: 40%;
    height: 0;
    width: 75%;
  }
}

@media screen and (min-width: 2000px) {
  #landing-page-container .buttons-container {
    margin-top: 3%;
    margin-bottom: 35%;
    height: 0;
    width: 83%;
  }

  #landing-page-container .Make-your-well-being-a-priority {
    font-size: 2.5vw;
    width: 83%;
  }

  #landing-page-container .left-side-container {
    width: 65%;
  }

  #landing-page-container .image-container {
    width: 65%;
    height: 90%;
  }

  #landing-page-container .texto {
    font-size: 1.25vw;
    width: 83%;
    margin-bottom: 5%;
    margin-top: 5%;
  }

  #landing-page-container .Main-Button-Outline-Avenir-Desktop {
    width: 14.8vw;
    border-radius: 50px;
    height: 5vw;
  }

  #landing-page-container .Main-Button-Fill-Avenir-Desktop {
    width: 14.8vw;
    border-radius: 50px;
    height: 5vw;
  }

  #landing-page-container .buttons-container {
    margin-top: 5%;
    margin-bottom: 35%;
    height: 0;
  }

  #landing-page-container .firstImage,
  #landing-page-container .secondImage,
  #landing-page-container .thirdImage {
    width: 46vw;
    height: 30vw;
    margin-top: 10%;
  }
}
