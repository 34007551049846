#footer-container {
  background-color: var(--purple-1);
  padding-top: 3.5vh;
  padding-bottom: 3.5vh;
  width: 100%;
}

#footer-container .footer-options {
  color: white;
  font-family: "PoppinsRegular";
}

#footer-container #purple-logo {
  cursor: pointer;
}

@media screen and (max-width: 575px) {
  #footer-container {
    padding-top: 3vh;
    padding-bottom: 3vh;
  }

  #footer-container .footer-options {
    height: 5vh;
    font-size: 3vw;
  }

  #footer-container #purple-logo {
    width: 3vw;
    margin-left: 10%;
  }

  #footer-container #purple-logo-container {
    width: 7vw;
    margin-right: 1vw;
  }
}

@media screen and (min-width: 576px) {
  #footer-container {
    padding-top: 3.5vh;
    padding-bottom: 3.5vh;
  }

  #footer-container .footer-options {
    font-size: 1.5vw;
    width: 80%;
  }

  #left-sentence {
    flex: 60%;
    margin-left: -1.5vw;
  }

  #terms {
    flex: 10%;
  }

  #policy {
    flex: 10%;
  }

  #footer-container #purple-logo {
    width: 2vw;
    margin-left: 10%;
  }

  #footer-container #purple-logo-container {
    width: 7vw;
    margin-right: 1%;
  }
}

@media screen and (min-width: 768px) {
  #footer-container .footer-options {
    font-size: 1.2vw;
  }
}

@media screen and (min-width: 992px) {
  #footer-container .footer-options {
    font-size: 1vw;
  }

  #footer-container #purple-logo {
    width: 1.5vw;
    margin-left: 10%;
  }

  #footer-container #purple-logo-container {
    width: 7vw;
    margin-right: 1%;
  }
}
